export class RadarChartSeriesOptions {
    readonly type = 'radar';
    id?: string;
    name?: string;
    data: RadarChartSeriesDataOptions[] = [];

    constructor(base?: Partial<RadarChartSeriesOptions>) {
        if (base) {
            this.id = base.id ?? this.id;
            this.name = base.name ?? this.name;
            this.data = base.data ?? this.data;
        }
    }
}

export class RadarChartSeriesDataOptions {
    value: any;
    name: string;
    lineStyle?: RadarChartLineStyleOptions;
    itemStyle?: RadarChartItemStyleOptions;
    areaStyle?: RadarChartAreaStyleOptions;

    constructor(base?: Partial<RadarChartSeriesDataOptions>) {
        if (!!base) {
            this.value = base.value ?? this.value;
            this.name = base.name ?? this.name;
            this.lineStyle = base.lineStyle ?? this.lineStyle;
            this.itemStyle = base.itemStyle ?? this.itemStyle;
            this.areaStyle = base.areaStyle ?? this.areaStyle;
        }
    }
}

export class RadarChartLineStyleOptions {
    color = '#000';

    constructor(base?: Partial<RadarChartLineStyleOptions>) {
        if (base) {
            this.color = base.color ?? this.color;
        }
    }
}

export class RadarChartItemStyleOptions {
    color = '#000';

    constructor(base?: Partial<RadarChartItemStyleOptions>) {
        if (base) {
            this.color = base.color ?? this.color;
        }
    }
}

export class RadarChartAreaStyleOptions {
    color?: string;

    constructor(base?: Partial<RadarChartItemStyleOptions>) {
        if (base) {
            this.color = base.color ?? this.color;
        }
    }
}
