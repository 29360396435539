<div *ngIf="partDetail && parentFormGroup" [formGroup]="parentFormGroup" [id]="'id-' + part.id" class="part-content">
    <div
        *ngIf="this.touched && partDetail.required && (!this.partDetail.answers || this.partDetail.answers.length === 0)"
        class="error"
        dataFormat-name="form-component-required-message">
        <span>{{ partDetail.processedRequiredMessage }}</span>
    </div>
    <div class="question" dataFormat-name="form-component-question">
        <span
            >{{ partDetail.processedLabel }}
            <ng-container *ngIf="partDetail.processedLabel && partDetail.processedLabel !== '' && partDetail.required">*</ng-container
            ><mat-icon
                dataFormat-name="form-component-description"
                color="primary"
                class="help-icon"
                *ngIf="partDetail.help"
                [showTrigger]="NgxFloatUiTriggers.hover"
                appendTo="body"
                [floatUi]="questionInformationPopover"
                >help</mat-icon
            >
        </span>
        <float-ui-content #questionInformationPopover>
            <div class="popover">
                <div [innerHTML]="partDetail.processedHelpInformation"></div>
            </div>
        </float-ui-content>
    </div>

    <mat-grid-list
        rowHeight="1.5em"
        [cols]="partStyle?.normalStyle?.columnsStyle?.columnAmount ? partStyle.normalStyle?.columnsStyle.columnAmount : 1"
        [gutterSize]="partStyle?.normalStyle?.columnsStyle?.columnGutter ? partStyle?.normalStyle?.columnsStyle?.columnGutter : '10px'"
        gutterSize="10px">
        <mat-grid-tile *ngIf="partDetail.addSelectAll">
            <mat-checkbox
                class="choice"
                color="primary"
                [disabled]="!this.partDetail.enabled"
                formControlName="allSelected"
                (change)="onToggleAll($event.checked)">
                {{ partDetail.selectAllText }}
            </mat-checkbox>
        </mat-grid-tile>
        <ng-container *ngFor="let choice of this.partDetail.choices">
            <mat-grid-tile>
                <mat-checkbox
                    class="choice"
                    color="primary"
                    [disabled]="!this.partDetail.enabled"
                    [checked]="this.partDetail.answers?.indexOf(choice.code) > -1"
                    (change)="choice.selected = $event.checked; onChangeAnswers(choice, $event.checked)"
                    >{{ choice.label }}
                    <mat-icon
                        color="primary"
                        class="help-icon"
                        *ngIf="choice.help"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        appendTo="body"
                        [floatUi]="choiceInformationPopover"
                        >help
                    </mat-icon>
                    <float-ui-content #choiceInformationPopover>
                        <div class="popover">
                            <div [innerHTML]="choice.helpInformation"></div>
                        </div>
                    </float-ui-content>
                </mat-checkbox>
            </mat-grid-tile>
        </ng-container>
    </mat-grid-list>
</div>
