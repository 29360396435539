import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { DomSanitizer } from '@angular/platform-browser';
import {
    addTemplateResult,
    replaceArguments,
    TemplatePartDetail,
} from '../../../../../../../../../../frontoffice/data-access/template/src/lib/components/layout/template/template-part.detail';
import { TemplatePartStyle } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/components/layout/template/template-part.style';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { ActivatedRoute } from '@angular/router';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'app-template-part-front',
    templateUrl: './template-part-front.component.html',
    styleUrls: ['./template-part-front.component.scss'],
})
export class TemplatePartFrontComponent implements ComponentFront, OnInit, OnChanges {
    @Input()
    partDetail: TemplatePartDetail = null;

    @Input()
    partStyle: TemplatePartStyle = null;

    @Input()
    host = '';

    @Input()
    part: Part = null;

    templateVersion: TemplateVersion;

    @Input()
    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
        templateVersion?: TemplateVersion;
        executionResultPartId?: string;
    }>;

    parentFormGroup: FormGroup;

    processedArguments: TemplateArgument[];

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public sanitizer: DomSanitizer,
        private route: ActivatedRoute
    ) {}

    async ngOnInit(): Promise<void> {
        const _arguments = this.initializeArguments();
        this.processedArguments = await replaceArguments(this.partDetail, _arguments);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        this.processedArguments = await replaceArguments(this.partDetail, this.initializeArguments());
    }
    onTemplateResult($event: { id: string; name: string; value: string }) {
        addTemplateResult(this.partDetail, $event);
    }

    onTemplateLoaded($event: TemplateVersion) {
        this.partDetail.templateVersion = $event;
    }

    initializeArguments(): TemplateArgument[] {
        const templateArguments = [...this.templateVersion.arguments];
        if (this.partDetail.arguments) {
            for (let i = 0; i < this.partDetail.arguments.length; i++) {
                const argument = this.partDetail.arguments[i];
                const templateVersionArgumentIndex = templateArguments.findIndex(
                    templateVersionArgument => argument.name === templateVersionArgument.name
                );
                if (templateVersionArgumentIndex > -1 && !argument.calculatedValue) {
                    argument.calculatedValue = templateArguments[templateVersionArgumentIndex].calculatedValue;
                }
            }
        }
        return templateArguments;
    }
}
