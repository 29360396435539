import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { VerticalDividerPartDetail } from '../../model/vertical-divider-part.detail';
import { VerticalDividerPartStyle } from '../../model/vertical-divider-part.style';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'app-vertical-divider-front',
    templateUrl: './vertical-divider-front.component.html',
    styleUrls: ['./vertical-divider-front.component.scss'],
})
export class VerticalDividerFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: VerticalDividerPartDetail = null;
    partStyle: VerticalDividerPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{ trigger: string; actionLinks: PartActionLink[]; arguments: TemplateArgument[] }>;

    parentFormGroup: FormGroup;

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {}
}
