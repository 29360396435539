import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtpQrPartFrontComponent } from './components/otp-qr-part-front/otp-qr-part-front.component';
import { getPartDetailComponents } from '../../../part-module';

@NgModule({
    declarations: [OtpQrPartFrontComponent],
    imports: [CommonModule],
})
export class OtpQrPartModule {
    constructor() {
        getPartDetailComponents().set('otp-qr', OtpQrPartFrontComponent);
    }
}
