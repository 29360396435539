import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { getPartDetailComponents } from '../../../../../part-module';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'tabs-part',
    templateUrl: './part.component.html',
    styleUrls: ['./part.component.scss'],
})
export class PartComponent implements OnInit, OnChanges, AfterViewInit {
    @Input()
    part: Part;

    @Input()
    partContainer: Part;

    @Input()
    templateVersion: TemplateVersion;

    @Input()
    application: ApplicationDto;

    @Input()
    parentFormGroup: FormGroup;

    @Input()
    host: string;

    @Input()
    currentScreenType: number;

    @ViewChild('partDetail', { read: ViewContainerRef }) partDetail: ViewContainerRef;

    @Output()
    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }> = new EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>();

    private componentRef: ComponentRef<ComponentFront>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.loadComponent();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.componentRef && this.componentRef.instance) {
            this.componentRef.instance.partDetail = this.part.detail;
            this.componentRef.instance.part = this.part;
            this.componentRef.instance.partStyle = this.part.style;
            this.componentRef.instance.templateVersion = this.templateVersion;
            this.componentRef.instance.application = this.application;
            this.componentRef.instance.executeAction = this.executeAction;
            this.componentRef.instance.parentFormGroup = this.parentFormGroup;

            if ('host' in this.componentRef.instance) {
                // @ts-ignore
                this.componentRef.instance['host'] = this.host;
            }
            this.componentRef.instance.ngOnChanges(changes);
            this.componentRef.instance.changeDetectorRef.detectChanges();
        }
    }

    loadComponent(): void {
        const partDetailComponentType: Type<ComponentFront> | undefined = getPartDetailComponents()?.get(this.part.detail.partType);
        if (partDetailComponentType) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(partDetailComponentType!);
            this.partDetail.clear();
            const componentRef = this.partDetail.createComponent<ComponentFront>(componentFactory);
            this.componentRef = componentRef;
            this.componentRef.instance.partDetail = this.part.detail;
            this.componentRef.instance.part = this.part;
            this.componentRef.instance.partStyle = this.part.style;
            this.componentRef.instance.templateVersion = this.templateVersion;
            this.componentRef.instance.application = this.application;
            this.componentRef.instance.executeAction = this.executeAction;
            this.componentRef.instance.parentFormGroup = this.parentFormGroup;
            if ('host' in this.componentRef.instance) {
                // @ts-ignore
                this.componentRef.instance['host'] = this.host;
            }
            this.componentRef.instance.ngOnInit();
            this.componentRef.instance.changeDetectorRef.detectChanges();
        }
    }
}
