import { AppState } from '../app.state';
import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { Part } from '@frontoffice/data-access/template';
import { createSelector } from '@ngrx/store';

const selectState = (state: AppState) => state;

const partAdapter = createEntityAdapter<Part>();

const { selectIds, selectAll, selectTotal, selectEntities } = partAdapter.getSelectors();
export const partSelectors = {
    ids: createSelector(selectState, () => selectIds),
    all: createSelector(selectState, () => selectAll),
    total: createSelector(selectState, () => selectTotal),
    entities: createSelector(selectState, state => selectEntities(state.parts)),
    byId: (id: string) =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<Part> = selectEntities(state.parts);
            return dictionary[id];
        }),
};
