import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { Router } from '@angular/router';
import { TemplateFacade } from '../../../../../data-access/template/src/lib/facade/template.facade';

declare var routeToResetPasswordPage: any;

@Injectable()
export class RouteToResetPasswordPage implements Method {
    constructor(
        private router: Router,
        private templateFacade: TemplateFacade
    ) {}

    execute(executionScope: ExecutionScope): void {
        routeToResetPasswordPage();
    }
}
