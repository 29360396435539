import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import {
    containsPlaceholders,
    replaceArgumentsForString,
} from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export interface HtmlPartDetail extends PartDetail {
    code: string;
    html: string;
    processedHtml: string;
    css: string;
    javascriptUrls: LinkDto[];
    cssUrls: LinkDto[];
    processedCss: string;
    preJavascript: string;
    processedPreJavascript: string;
    processedPreJavascriptSuccess: boolean;
    postJavascript: string;
    processedPostJavascript: string;
    processedPostJavascriptSuccess: boolean;
    changedJavascript: string;
    processedChangedJavascript: string;
}

export interface LinkDto {
    id: string;
    name: string;
    url: string;
    async: boolean;
    module: boolean;
}

export async function replaceArguments(detail: HtmlPartDetail, templateArguments: TemplateArgument[]) {
    detail.processedHtml = await replaceArgumentsForString(detail.html, templateArguments);
    detail.processedCss = await replaceArgumentsForString(detail.css, templateArguments);
    detail.processedPreJavascript = await replaceArgumentsForString(detail.preJavascript, templateArguments, false);
    detail.processedPostJavascript = await replaceArgumentsForString(detail.postJavascript, templateArguments, false);
    detail.processedPreJavascriptSuccess = !containsPlaceholders(detail.processedPreJavascript);
    detail.processedPostJavascriptSuccess = !containsPlaceholders(detail.processedPostJavascript);
}
