import { GridStyleDto } from '../models/grid.style.dto';

export interface TemplateStyleDto {
    backgroundColor: string;
    backgroundImage: string;
    font: string;
    fontSize: string;
    fontColor: string;
    fontWeight: string;
    grid: GridStyleDto;
}
