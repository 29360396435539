import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export interface ButtonPartDetail extends PartDetail {
    code: string;
    label: string;
    processedLabel: string;
    type: string;
    enabled: boolean;
    tooltip: string;
    processedTooltip: string;
    icon: string;
    processedIcon: string;
    iconPosition: string;
}

export async function replaceArguments(detail: ButtonPartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    detail.processedLabel = await replaceArgumentsForString(detail.label, templateArguments);
    detail.processedTooltip = await replaceArgumentsForString(detail.tooltip, templateArguments);
    detail.processedIcon = await replaceArgumentsForString(detail.icon, templateArguments);
}
