<mat-accordion *ngIf="partDetail && tabsInitialized" [id]="'id-' + part.id" class="part-content">
    <mat-expansion-panel
        *ngFor="let accordionItem of partDetail.accordionItems; index as i; trackBy: identifyAccordionItem"
        (opened)="onAccordionItemOpened()">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon *ngIf="accordionItem.icon">{{ accordionItem.icon }}</mat-icon>
                {{ accordionItem.name }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="subcontainer" [id]="accordionItem.code" #grid>
            <ng-container *ngFor="let child of accordionParts.get(accordionItem.id); trackBy: identifyPart">
                <ng-container *ngIf="!child.hidden">
                    <accordion-part
                        [attr.id]="'part-' + child.id"
                        [partId]="child.selectorId"
                        [partContainer]="this.part"
                        [host]="host"
                        [templateVersion]="templateVersion"
                        [application]="application"
                        [parentFormGroup]="parentFormGroup"
                        (executeAction)="executeAction.emit($event)">
                    </accordion-part>
                </ng-container>
            </ng-container>
        </div>
    </mat-expansion-panel>
</mat-accordion>
