import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgHttpCachingModule } from 'ng-http-caching';
import { TemplateFrontComponent } from './components/template-front/template-front.component';
import { PartComponent } from './components/part/part.component';
import { TemplateComponent } from './components/template/template.component';

@NgModule({
    declarations: [TemplateFrontComponent, PartComponent, TemplateComponent],
    exports: [TemplateFrontComponent, PartComponent],
    imports: [CommonModule, NgHttpCachingModule],
})
export class TemplateFrontModule {}
