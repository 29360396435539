import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioButtonFieldPartFrontComponent } from './components/radio-button-field-part-front/radio-button-field-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [RadioButtonFieldPartFrontComponent],
    imports: [
        CommonModule,
        NgxFloatUiModule,
        MatIconModule,
        MatRadioModule,
        MatInputModule,
        MatGridListModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class RadioButtonFieldPartModule {
    constructor() {
        getPartDetailComponents().set('radiobutton-field', RadioButtonFieldPartFrontComponent);
    }
}
