<div *ngIf="partDetail">
    <button
        mat-raised-button
        color="primary"
        [id]="'id-' + part.id"
        class="part-content"
        preventDoubleClick
        [disabledByPart]="!partDetail.enabled"
        (click)="onClick()"
        (keyup.enter)="onClick()"
        [disabled]="!partDetail.enabled"
        [matTooltip]="partDetail.processedTooltip">
        <mat-icon *ngIf="partDetail.icon && (!partDetail.iconPosition || partDetail.iconPosition === 'left')">{{
            partDetail.processedIcon
        }}</mat-icon>
        {{ partDetail.processedLabel }}
        <mat-icon iconPositionEnd *ngIf="partDetail.icon && partDetail.iconPosition === 'right'">{{ partDetail.processedIcon }}</mat-icon>
    </button>
</div>
