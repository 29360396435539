import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ChipsInputFieldPartDetail } from '../../model/chips-input-field-part.detail';
import { ChipsInputFieldPartStyle } from '../../model/chips-input-field-part.style';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'app-chips-input-field-part-front',
    templateUrl: './chips-input-field-part-front.component.html',
})
export class ChipsInputFieldPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: ChipsInputFieldPartDetail = null;
    partStyle: ChipsInputFieldPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    chips: string[];

    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    formGroup: FormGroup;

    answerControlName: string;

    parentFormGroup: FormGroup;

    get myControl(): FormControl {
        return this.parentFormGroup.get(this.answerControlName) as FormControl;
    }

    constructor(
        public readonly changeDetectorRef: ChangeDetectorRef,
        private readonly fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.initChips();

        this.answerControlName = this.part.instanceIdentifier;
        this.parentFormGroup.addControl(this.answerControlName, new FormControl(this.partDetail.answers));

        if (this.partDetail.required) {
            this.parentFormGroup.get(this.answerControlName).setValidators([Validators.required]);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {}

    addChip(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        if (value) {
            this.chips.push(value);
        }

        event.chipInput!.clear();
        this.partDetail.answers = this.chips;
        this.executeOnChangeAction();
    }

    removeChip(chip: string): void {
        const index = this.chips.indexOf(chip);

        if (index >= 0) {
            this.chips.splice(index, 1);
        }
        this.partDetail.answers = this.chips;
        this.executeOnChangeAction();
    }

    initChips(): void {
        if (!!this.partDetail.answers && this.partDetail.answers.length > 0) {
            this.chips = this.partDetail.answers;
        } else {
            this.chips = [];
        }
    }

    executeOnChangeAction(): void {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CHANGE');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks: actionLinks,
                arguments: templateActionArguments,
            });
        }
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
