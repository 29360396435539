import { ArgumentDto } from './argument.dto';
import { ActionExecutionFrontEndActionDto } from '@frontoffice/data-access/template';
import { GUIDFunctions } from '@shared/utils';
import { NextInvocationDto } from './next-invocation.dto';
import { InvocationOutputDto } from './invocation-output.dto';

export interface InvocationDto {
    id: string;
    methodKey: string;
    arguments: ArgumentDto[];
    nextInvocations: NextInvocationDto[];
    invocationOutputs: InvocationOutputDto[];

    //deprecated
    value?: string;
    //deprecated
    webComponentValue?: string;
}

export function createInvocationChain(frontEndActions: ActionExecutionFrontEndActionDto[]): InvocationDto[] {
    let lastInvocation: InvocationDto;
    const invocations: InvocationDto[] = [];
    frontEndActions.forEach(frontEndAction => {
        const invocationArguments: ArgumentDto[] = [];
        const argumentKeys = Object.keys(frontEndAction.arguments);
        if (argumentKeys && argumentKeys.length > 0) {
            argumentKeys.forEach(key => {
                invocationArguments.push({
                    name: key,
                    value: frontEndAction.arguments[key],
                });
            });
        }
        const invocation: InvocationDto = {
            id: new GUIDFunctions().newGuid(),
            methodKey: frontEndAction.type,
            arguments: invocationArguments,
            nextInvocations: [],
            invocationOutputs: [],
        };
        invocations.push(invocation);
        if (lastInvocation) {
            lastInvocation.nextInvocations.push({
                id: new GUIDFunctions().newGuid(),
                invocationId: invocation.id,
            });
        }
        lastInvocation = invocation;
    });
    return invocations;
}
