import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreemapPartFrontComponent } from './components/treemap-part-front/treemap-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
    declarations: [TreemapPartFrontComponent],
    imports: [CommonModule, NgxEchartsModule],
})
export class TreemapPartModule {
    constructor() {
        getPartDetailComponents().set('tree-map', TreemapPartFrontComponent);
    }
}
