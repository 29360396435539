import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';

declare var updatePassword: any;

@Injectable()
export class UpdatePassword implements Method {
    execute(executionScope: ExecutionScope): void {
        updatePassword(executionScope.getRequiredVariable('password'));
    }
}
