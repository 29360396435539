import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { TabsPartDetail } from '../../model/tabs-part.detail';
import { TabsPartStyle } from '../../model/tabs-part.style';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import {
    getClosestPartPositioning,
    PartPositioning,
} from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-positioning.dto';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BehaviorSubject, debounceTime, Subscription } from 'rxjs';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

declare let jQuery: any;

@Component({
    selector: 'app-tabs-part-front',
    templateUrl: './tabs-part-front.component.html',
    styleUrls: ['./tabs-part-front.component.scss'],
})
export class TabsPartFrontComponent implements ComponentFront, OnInit, OnChanges, OnDestroy, AfterViewInit {
    partDetail: TabsPartDetail = null;
    partStyle: TabsPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    host = '';

    tabsInitialized = false;

    resizeObserver: ResizeObserver;

    mutationObserver: MutationObserver;

    subscriptions: Subscription = new Subscription();

    private resizePart: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public window: Window
    ) {}

    ngOnInit(): void {
        this.partDetail.openedTab = 0;
        this.initPartsOnTabs();
        this.subscriptions.add(
            this.resizePart.pipe(debounceTime(20)).subscribe(resizeNumber => {
                this.adjustHeightOfSubContainer();
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initPartsOnTabs();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
        }
    }

    ngAfterViewInit(): void {
        this.initResizeObservers();
    }

    initResizeObservers() {
        const element = document.querySelector('#id-' + this.part?.id);
        if (element) {
            this.resizeObserver = new ResizeObserver(entries => {
                this.resizePart.next(this.resizePart.value + 1);
            });
            this.resizeObserver.observe(element);

            this.mutationObserver = new MutationObserver(() => {
                this.resizePart.next(this.resizePart.value + 1);
            });
            this.mutationObserver.observe(element, {
                childList: true,
                attributes: false,
                subtree: true,
            });
        }
    }
    setParentHeight(parent: any, value: string) {
        if (parent.css('height') !== value) {
            parent.height(value);
        }
    }

    setParentWidth(parent: any, value: string) {
        if (parent.css('width') !== value) {
            parent.width(value);
        }
    }

    adjustHeightOfSubContainer(): void {
        const partPositioning: PartPositioning = getClosestPartPositioning(window.innerWidth, window.innerHeight, this.part.positions);
        const parent = jQuery('#id-' + this.part?.id + ' mat-tab-body.mat-mdc-tab-body-active > .mat-mdc-tab-body-content > .subcontainer');
        let listItems = null;
        if (partPositioning.sizeYUnit === 'fit-content' || partPositioning.sizeXUnit === 'fit-content') {
            listItems = jQuery(
                '#id-' + this.part?.id + ' mat-tab-body.mat-mdc-tab-body-active > .mat-mdc-tab-body-content > .subcontainer > tabs-part'
            );
        }

        if (partPositioning.sizeYUnit == 'fit-content') {
            let highestHeight = 0;
            for (let i = 0; i < listItems.length; i++) {
                const content = listItems.eq(i);
                const height = content.get(0).getBoundingClientRect().height;
                const top: number = content.get(0).getBoundingClientRect().top - parent.get(0).getBoundingClientRect().top;
                if (height + top > highestHeight) {
                    highestHeight = height + top;
                }
            }

            this.setParentHeight(parent, Math.round(highestHeight) + 'px');
        }

        if (partPositioning.sizeXUnit === 'fit-content') {
            let highestWidth = 0;
            for (let i = 0; i < listItems.length; i++) {
                const content = listItems.eq(i);
                const width = content.get(0).getBoundingClientRect().width;
                const left: number = content.get(0).getBoundingClientRect().left - parent.get(0).getBoundingClientRect().left;
                if (width + left > highestWidth) {
                    highestWidth = width + left;
                }
            }
            this.setParentWidth(parent, Math.round(highestWidth) + 'px');
        }
    }

    identifyPart(index, item): string {
        // Adding templateID to identify this part is a very hacky way to make sure template parts should be refreshed if
        // They have the same selectorId but a different template configured.
        // This can happen when a user copies a page & then changes the template in the copied page.
        // Without templateID in this return value, hopping from the copied page to the original page & back will not result in changing the content of
        // the template component.
        return item.selectorId + item.detail['templateId'];
    }

    identifyTab(index, item): string {
        return item.id;
    }

    onTabOpened($event: MatTabChangeEvent) {
        setTimeout(() => {
            this.adjustHeightOfSubContainer();
        }, 200);
    }

    initPartsOnTabs(): void {
        this.partDetail?.tabs?.forEach(tab => (tab.parts = []));
        this.partDetail?.parts?.forEach(part => {
            const tab = this.partDetail.tabs.find(tab => tab.code === part.secondaryContainerId);
            if (tab) {
                tab.parts.push(part);
            }
        });
        this.tabsInitialized = true;
        this.changeDetectorRef.detectChanges();
    }
}
