import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export class SlideToggleFieldPartDetail extends PartDetail {
    code: string;
    label?: string;
    processedLabel?: string;
    answer?: boolean;
    unProcessedAnswer?: string;
    processedAnswer?: boolean;

    help: boolean;
    helpInformation: string;
    processedHelpInformation?: string;

    enabled: boolean;
}

export async function replaceArguments(detail: SlideToggleFieldPartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    detail.processedLabel = await replaceArgumentsForString(detail.label, templateArguments);
    detail.processedHelpInformation = await replaceArgumentsForString(detail.helpInformation, templateArguments);
    if (detail.unProcessedAnswer) {
        detail.processedAnswer = Boolean(await replaceArgumentsForString(detail.unProcessedAnswer, templateArguments));
    } else {
        detail.processedAnswer = detail.answer;
    }
}
