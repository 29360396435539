import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatePartFrontComponent } from './components/template-part-front/template-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgHttpCachingModule } from 'ng-http-caching';
import { TemplateFrontModule } from '../../template-front/template-front.module';

@NgModule({
    declarations: [TemplatePartFrontComponent],
    exports: [TemplatePartFrontComponent],
    imports: [CommonModule, NgHttpCachingModule, TemplateFrontModule],
})
export class TemplatePartModule {
    constructor() {
        getPartDetailComponents().set('template', TemplatePartFrontComponent);
    }
}
