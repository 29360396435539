import { ScreenType, ScreenTypeMaxWidth, ScreenTypeMinWidth } from './screentype.enum';

export interface PartPositioning {
    id: string;
    x: number;
    y: number;
    positionXUnit: string;
    positionYUnit: string;
    sizeX: number;
    sizeY: number;
    sizeXUnit: string;
    sizeYUnit: string;
    horizontalAlignment: string;
    verticalAlignment: string;
    inheritContentSize: boolean;
    fixedPosition: boolean;
    hidden: boolean;
    zIndex: number;
    screenType: string;
}

export function getClosestPartPositioning(minWidth: number, maxWidth: number, partPositioningList: PartPositioning[]): PartPositioning {
    const sortedPositions = partPositioningList
        .filter(partPosition => ScreenType[partPosition.screenType as keyof typeof ScreenType] !== ScreenType.ALL_SCREENS)
        .sort((a, b) => ScreenType[a.screenType as keyof typeof ScreenType] - ScreenType[b.screenType as keyof typeof ScreenType]);
    const allScreens = partPositioningList.find(
        partPosition => ScreenType[partPosition.screenType as keyof typeof ScreenType] === ScreenType.ALL_SCREENS
    );
    if (sortedPositions.length > 0) {
        let possiblePositioning = !!allScreens ? allScreens : sortedPositions[0];
        sortedPositions.forEach(partPositioning => {
            if (!!allScreens) {
                const minWidthForPartPositioning = ScreenTypeMinWidth.get(
                    ScreenType[partPositioning.screenType as keyof typeof ScreenType]
                );
                const maxWidthForPartPositioning = ScreenTypeMaxWidth.get(
                    ScreenType[partPositioning.screenType as keyof typeof ScreenType]
                );
                if (
                    (!minWidthForPartPositioning || minWidthForPartPositioning === minWidth) &&
                    (!maxWidthForPartPositioning || maxWidthForPartPositioning === maxWidth)
                ) {
                    possiblePositioning = partPositioning;
                    return;
                }
            } else {
                const minWidthForPartPositioning = ScreenTypeMinWidth.get(
                    ScreenType[partPositioning.screenType as keyof typeof ScreenType]
                );
                if (!minWidthForPartPositioning || minWidthForPartPositioning <= minWidth) {
                    possiblePositioning = partPositioning;
                } else if (minWidthForPartPositioning && minWidthForPartPositioning > minWidth) {
                    return;
                }
            }
        });
        return possiblePositioning;
    } else {
        return partPositioningList[0];
    }
}
