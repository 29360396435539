<div [id]="'id-' + part.id" class="part-content">
    <mat-form-field appearance="outline" *ngIf="partDetail.addFilter">
        <mat-label>{{ partDetail.filterLabel ? partDetail.filterLabel : 'Filter' }}</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input />
    </mat-form-field>
    <div>
        <table class="w-[100%]" mat-table [dataSource]="dataSource" matSort>
            <!-- Position Column -->
            <ng-container *ngFor="let column of partDetail.columns; trackBy: identifyColumn">
                <ng-container [matColumnDef]="column.id">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef [disabled]="!column.enableSort">{{ column.label }}</th>
                    <ng-container *ngIf="!column.contentType || column.contentType === '' || column.contentType === 'text'">
                        <td mat-cell *matCellDef="let element" (click)="onColumnClicked(column)">{{ element[column.id] }}</td>
                    </ng-container>
                    <ng-container *ngIf="column.contentType === 'template'">
                        <td mat-cell *matCellDef="let element" class="align-top" [style]="'vertical-align:top'">
                            <app-template-front
                                [style]="'display:block;'"
                                [identifier]="'id-' + column.id + '-' + element['_code']"
                                [templateId]="column.templateId"
                                [arguments]="element[column.id + '-arguments']"
                                [passArgumentToBackend]="false"
                                [host]="host"
                                [application]="application"
                                [parentFormGroup]="parentFormGroup"
                                (executeAction)="this.executeAction.emit($event)"></app-template-front>
                        </td>
                    </ng-container>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClicked(row)"></tr>
            <ng-container *ngIf="partDetail.showNoResults">
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell h-9 text-center" [attr.colspan]="displayedColumns.length">{{ partDetail.noResultsText }}</td>
                </tr>
            </ng-container>
        </table>
        <mat-paginator
            *ngIf="partDetail.enablePaging"
            [pageSize]="partDetail.pageSize"
            [pageSizeOptions]="[5, 10, 25, 100]"
            aria-label="Select page of users"></mat-paginator>
    </div>
</div>
