import { Component, Input, OnInit } from '@angular/core';
import { ActionLogService } from '../../service/action-log.service';
import { BehaviorSubject } from 'rxjs';
import { ActionExecutionLogDto } from '../../dto/action-execution-log.dto';

declare var $: any;

@Component({
    selector: 'app-developer-controls',
    templateUrl: './developer-controls.component.html',
    styleUrls: ['./developer-controls.component.scss'],
})
export class DeveloperControlsComponent implements OnInit {
    @Input()
    host: string;

    @Input()
    templateId: string;

    @Input()
    applicationId: string;

    @Input()
    companyId: string;

    showLogs: boolean = false;

    logs: BehaviorSubject<ActionExecutionLogDto[]> = new BehaviorSubject([]);

    expandedLogLines: string[] = [];

    constructor(private actionLogService: ActionLogService) {}

    addHeightToBody(): void {
        $('body').height('calc(100% + 335px)');
    }

    removeHeightFromBody(): void {
        $('body').height('calc(100%)');
    }

    ngOnInit(): void {
        const from: Date = new Date();
        from.setHours(from.getHours() - 1);
        this.actionLogService
            .findActionLogs(this.host, this.applicationId, this.companyId, this.templateId, null, from, new Date())
            .subscribe(actionLogs => {
                this.logs.next(actionLogs);
            });
    }

    refreshLogs(): void {
        const from: Date = new Date();
        from.setHours(from.getHours() - 1);
        this.actionLogService
            .findActionLogs(this.host, this.applicationId, this.companyId, this.templateId, null, from, new Date())
            .subscribe(actionLogs => {
                this.logs.next(actionLogs);
            });
    }

    getIcon(level: string): string {
        if (level === 'WARN') {
            return 'warning';
        } else if (level === 'ERROR') {
            return 'bug_report';
        } else if (level === 'DEBUG') {
            return 'bug_report';
        } else if (level === 'TRACE') {
            return 'info';
        } else if (level === 'INFO') {
            return 'info';
        }
        return 'info';
    }

    expandToggle(logLine: ActionExecutionLogDto): void {
        if (this.expandedLogLines.indexOf(logLine.id) > -1) {
            this.expandedLogLines.splice(this.expandedLogLines.indexOf(logLine.id), 1);
        } else {
            this.expandedLogLines.push(logLine.id);
        }
    }
}
