import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface TextPartDetail extends PartDetail {
    code: string;
    text: string;
    processedText: SafeHtml;
}

export async function replaceArguments(
    detail: TextPartDetail,
    templateArguments: TemplateArgument[],
    sanitizer: DomSanitizer
): Promise<void> {
    detail.processedText = sanitizer.bypassSecurityTrustHtml(await replaceArgumentsForString(detail.text, templateArguments));
}
