import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploaderPartFrontComponent } from './components/uploader-part-front/uploader-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgxUploaderModule } from 'ngx-uploader';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
    declarations: [UploaderPartFrontComponent],
    imports: [CommonModule, MatButtonModule, MatRippleModule, NgxUploaderModule],
})
export class UploaderPartModule {
    constructor() {
        getPartDetailComponents().set('uploader', UploaderPartFrontComponent);
    }
}
