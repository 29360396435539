import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadarChartPartFrontComponent } from './components/radar-chart-part-front/radar-chart-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
    declarations: [RadarChartPartFrontComponent],
    imports: [CommonModule, NgxEchartsModule],
})
export class RadarChartPartModule {
    constructor() {
        getPartDetailComponents().set('radar-chart', RadarChartPartFrontComponent);
    }
}
