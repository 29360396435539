import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticallistPartFrontComponent } from './components/verticallist-part-front/verticallist-part-front.component';
import { PartComponent } from './components/part/part.component';
import { getPartDetailComponents } from '../../../part-module';
import { TemplateFrontModule } from '../../template-front/template-front.module';

@NgModule({
    declarations: [VerticallistPartFrontComponent, PartComponent],
    imports: [CommonModule, TemplateFrontModule],
})
export class VerticallistPartModule {
    constructor() {
        getPartDetailComponents().set('vertical-list', VerticallistPartFrontComponent);
    }
}
