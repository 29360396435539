import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { ColorInputFieldPartDetail } from '../../model/color-input-field-part.detail';
import { ColorInputFieldPartStyle } from '../../model/color-input-field-part.style';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'app-color-input-field-part-front',
    templateUrl: './color-input-field-part-front.component.html',
})
export class ColorInputFieldPartFrontComponent implements ComponentFront, OnInit, OnChanges, OnDestroy {
    partDetail: ColorInputFieldPartDetail = null;
    partStyle: ColorInputFieldPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    answerControlName: string;

    parentFormGroup: FormGroup;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.changeDisabled(this.partDetail.enabled);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['part']?.previousValue?.detail?.answer !== changes['part']?.currentValue?.detail?.answer) {
            this.updateAnswer();
        }
        if (changes['part']?.previousValue?.detail?.enabled !== changes['part']?.currentValue?.detail?.enabled) {
            this.changeDisabled(changes['part']?.currentValue?.detail?.enabled);
        }
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl(this.answerControlName);
    }

    changeDisabled(enabled: boolean): void {
        if (enabled) {
            this.parentFormGroup.get(this.answerControlName).enable();
        } else {
            this.parentFormGroup.get(this.answerControlName).disable();
        }
    }

    updateAnswer(): void {
        if (this.parentFormGroup && this.parentFormGroup.get(this.answerControlName)) {
            this.parentFormGroup.get(this.answerControlName).setValue(this.partDetail.answer);
        }
    }

    initForm(): void {
        this.answerControlName = this.part.instanceIdentifier;
        this.parentFormGroup.addControl(this.answerControlName, new FormControl(this.partDetail.answer));

        if (this.partDetail.required) {
            this.parentFormGroup.get(this.answerControlName).setValidators([Validators.required]);
        }
    }

    onChangeAnswer(): void {
        if (this.partDetail.answer !== this.parentFormGroup.get(this.answerControlName).value) {
            this.partDetail.answer = this.parentFormGroup.get(this.answerControlName).value;
            this.executeOnChangeAction();
        }
    }

    executeOnChangeAction(): void {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CHANGE');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks: actionLinks,
                arguments: templateActionArguments,
            });
        }
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
