import { Type } from 'class-transformer';
import { PartActionLink } from '../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { PartDetail } from './part-detail.model';
import { PartStyle } from './part-style.model';
import { GridStyleDto } from './grid.style.dto';
import { PartPositioning } from './part-positioning.dto';

export class Part {
    id: string;
    instanceIdentifier?: string;
    selectorId: string;
    x: number;
    positionXUnit: string;
    y: number;
    positionYUnit: string;
    sizeX: number;
    sizeXUnit: string;
    sizeY: number;
    sizeYUnit: string;
    sizeXPx: number;
    sizeYPx: number;
    inheritContentSize: boolean;
    fixedPosition: boolean;
    containerType: string;
    containerId: string;
    secondaryContainerId: string;
    hidden: boolean;
    displayNone: boolean;
    displayBlock: boolean;
    partType: string;

    verticalAlignment: string;
    horizontalAlignment: string;
    detail: PartDetail;

    @Type(() => PartActionLink)
    actionLinks: PartActionLink[] = [];

    style: PartStyle;

    actionLinksByType?: Map<string, PartActionLink[]>;

    positions: PartPositioning[];

    public static getActionLinkOfType(part: Part, type: string): PartActionLink[] {
        return part.actionLinks.filter(actionLink => actionLink.type === type);
    }

    public static getPosition(part: Part, currentScreenType: number) {
        if (!!part.fixedPosition) {
            return 'fixed';
        }
        return null;
    }

    public static getPositionStyle(part: Part) {
        if (!!part.fixedPosition) {
            return 'fixed';
        }
        return null;
    }

    public static getWidth(part: Part, currentScreenType: number, ignoreInherit?: boolean, grid?: GridStyleDto): string {
        if (
            !part.inheritContentSize ||
            ignoreInherit ||
            part.detail.partType === 'vertical-list' ||
            part.detail.partType === 'checkbox-field' ||
            part.detail.partType === 'radiobutton-field' ||
            part.detail.partType === 'stepper'
        ) {
            if (part.sizeXUnit === 'pixels') {
                return part.sizeX + 'px';
            } else if (part.sizeXUnit === 'percentage') {
                return part.sizeX + '%';
            } else if (part.sizeXUnit === 'viewport') {
                return part.sizeX + 'vh';
            } else if (part.sizeXUnit === 'grid' && !!grid) {
                const amountOfGaps = grid.gridColumns + 1;
                const gapSize = grid.gridColumnGapSize;
                const amountOfColumns = grid.gridColumns;
                const amountOfGapsCovered = part.sizeX - 1;
                return (
                    'calc(((100% - ' +
                    gapSize * amountOfGaps +
                    'px) / ' +
                    amountOfColumns +
                    ') * ' +
                    part.sizeX +
                    ' + ' +
                    gapSize * amountOfGapsCovered +
                    'px)'
                );
            } else if (part.sizeXUnit === 'grid-end' && !!grid) {
                const leftX = part.x + part.positionXUnit;
                const amountOfGaps = grid.gridColumns + 1;
                const gapSize = grid.gridColumnGapSize;
                const amountOfColumns = grid.gridColumns;
                const amountOfGapsCovered = part.sizeX - 1;
                return (
                    'calc(((100% - ' +
                    gapSize * amountOfGaps +
                    'px) / ' +
                    amountOfColumns +
                    ') * ' +
                    part.sizeX +
                    ' + ' +
                    gapSize * amountOfGapsCovered +
                    'px ' +
                    ' - ' +
                    leftX +
                    ')'
                );
            } else if (part.sizeXUnit === 'unset') {
                return 'unset';
            } else {
                return part.sizeX + 'px';
            }
        } else {
            return 'fit-content';
        }
    }

    public static getWidthStyle(part: Part, ignoreInherit?: boolean, grid?: GridStyleDto): string {
        if (
            !part.inheritContentSize ||
            ignoreInherit ||
            part.detail.partType === 'vertical-list' ||
            part.detail.partType === 'checkbox-field' ||
            part.detail.partType === 'radiobutton-field' ||
            part.detail.partType === 'stepper'
        ) {
            if (part.sizeXUnit === 'pixels') {
                return part.sizeX + 'px';
            } else if (part.sizeXUnit === 'percentage') {
                return part.sizeX + '%';
            } else if (part.sizeXUnit === 'viewport') {
                return part.sizeX + 'vh';
            } else if (part.sizeXUnit === 'grid' && !!grid) {
                const amountOfGaps = grid.gridColumns + 1;
                const gapSize = grid.gridColumnGapSize;
                const amountOfColumns = grid.gridColumns;
                const amountOfGapsCovered = part.sizeX - 1;
                return (
                    'calc(((100% - ' +
                    gapSize * amountOfGaps +
                    'px) / ' +
                    amountOfColumns +
                    ') * ' +
                    part.sizeX +
                    ' + ' +
                    gapSize * amountOfGapsCovered +
                    'px)'
                );
            } else if (part.sizeXUnit === 'unset') {
                return 'unset';
            } else if (part.sizeXUnit === 'fit-content') {
                return 'fit-content';
            } else {
                return part.sizeX + 'px';
            }
        } else {
            return 'fit-content';
        }
    }

    public static getHeight(part: Part, currentScreenType: number, ignoreInherit?: boolean, grid?: GridStyleDto): string {
        if (!part.inheritContentSize || ignoreInherit || part.detail.partType === 'horizontal-list') {
            if (part.sizeYUnit === 'pixels') {
                return part.sizeY + 'px';
            } else if (part.sizeYUnit === 'percentage') {
                return part.sizeY + '%';
            } else if (part.sizeYUnit === 'viewport') {
                return part.sizeY + 'vh';
            } else if (part.sizeYUnit === 'grid' && !!grid) {
                const amountOfGaps = grid.gridRows + 1;
                const gapSize = grid.gridRowGapSize;
                const amountOfRows = grid.gridRows;
                const amountOfGapsCovered = part.sizeY - 1;
                return (
                    'calc(((100% - ' +
                    gapSize * amountOfGaps +
                    'px) / ' +
                    amountOfRows +
                    ') * ' +
                    part.sizeY +
                    ' + ' +
                    gapSize * amountOfGapsCovered +
                    'px)'
                );
            } else if (part.sizeYUnit === 'unset') {
                return 'unset';
            } else if (part.sizeYUnit === 'fit-content') {
                return 'fit-content';
            } else {
                return part.sizeX + 'px';
            }
        } else {
            return 'fit-content';
        }
    }

    public static getHeightStyle(part: Part, ignoreInherit?: boolean, grid?: GridStyleDto): string {
        if (!part.inheritContentSize || ignoreInherit || part.detail.partType === 'horizontal-list') {
            if (part.sizeYUnit === 'pixels') {
                return part.sizeY + 'px';
            } else if (part.sizeYUnit === 'percentage') {
                return part.sizeY + '%';
            } else if (part.sizeYUnit === 'viewport') {
                return part.sizeY + 'vh';
            } else if (part.sizeYUnit === 'grid' && !!grid) {
                const amountOfGaps = grid.gridRows + 1;
                const gapSize = grid.gridRowGapSize;
                const amountOfRows = grid.gridRows;
                const amountOfGapsCovered = part.sizeY - 1;
                return (
                    'calc(((100% - ' +
                    gapSize * amountOfGaps +
                    'px) / ' +
                    amountOfRows +
                    ') * ' +
                    part.sizeY +
                    ' + ' +
                    gapSize * amountOfGapsCovered +
                    'px)'
                );
            } else if (part.sizeYUnit === 'unset') {
                return 'unset';
            } else if (part.sizeYUnit === 'fit-content') {
                return 'fit-content';
            } else {
                return part.sizeY + 'px';
            }
        } else {
            return 'fit-content';
        }
    }

    public static isContainerComponent(part: Part) {
        return (
            part.partType === 'vertical-list' ||
            part.partType === 'horizontal-list' ||
            part.partType === 'tabs' ||
            part.partType === 'stepper' ||
            part.partType === 'accordion' ||
            part.partType === 'plane'
        );
    }

    public static isFormComponent(part: Part) {
        return (
            part.partType === 'radiobutton-field' ||
            part.partType === 'checkbox-field' ||
            part.partType === 'slide-toggle' ||
            part.partType === 'input-field' ||
            part.partType === 'dropdown-field' ||
            part.partType === 'date-field' ||
            part.partType === 'number-input-field' ||
            part.partType === 'password-input-field' ||
            part.partType === 'email-input-field' ||
            part.partType === 'chips-input-field' ||
            part.partType === 'url-input-field' ||
            part.partType === 'color-input-field' ||
            part.partType === 'text-field' ||
            part.partType === 'slider-field' ||
            part.partType === 'slide-toggle'
        );
    }

    public static getTranslate(part: Part) {
        let xPosition: string;
        if (part.positionXUnit === 'pixels') {
            xPosition = '0';
        } else if (part.positionXUnit === 'percentage') {
            xPosition = '0';
        } else if (part.positionXUnit === 'viewport') {
            xPosition = '0';
        }
        let yPosition: string;
        if (part.positionYUnit === 'pixels') {
            yPosition = '0';
        } else if (part.positionYUnit === 'percentage') {
            yPosition = '0';
        } else if (part.positionYUnit === 'viewport') {
            yPosition = '0';
        }
        return `translate(${xPosition}, ${yPosition})`;
    }

    public static getHorizontalPositioning(part: Part, currentScreenType: number, grid?: GridStyleDto): string {
        let xPosition: string;
        if (part.positionXUnit === 'pixels') {
            xPosition = part.x + 'px';
        } else if (part.positionXUnit === 'percentage') {
            xPosition = part.x + '%';
        } else if (part.positionXUnit === 'viewport') {
            xPosition = part.x + 'vh';
        } else if (part.positionXUnit === 'grid' && !!grid) {
            const amountOfGaps = grid.gridColumns + 1;
            const gapSize = grid.gridColumnGapSize;
            const amountOfColumns = grid.gridColumns;
            const amountOfGapsCovered = part.x + 1;
            return (
                'calc(((100% - ' +
                gapSize * amountOfGaps +
                'px) / ' +
                amountOfColumns +
                ') * ' +
                part.x +
                ' + ' +
                gapSize * amountOfGapsCovered +
                'px)'
            );
        }
        return xPosition;
    }

    public static getVerticalPositioning(part: Part, currentScreenType: number, grid?: GridStyleDto): string {
        let yPosition: string;
        if (part.positionYUnit === 'pixels') {
            yPosition = part.y + 'px';
        } else if (part.positionYUnit === 'percentage') {
            yPosition = part.y + '%';
        } else if (part.positionYUnit === 'viewport') {
            yPosition = part.y + 'vh';
        } else if (part.positionYUnit === 'grid' && !!grid) {
            const amountOfGaps = grid.gridRows + 1;
            const gapSize = grid.gridRowGapSize;
            const amountOfRows = grid.gridRows;
            const amountOfGapsCovered = part.y + 1;
            return (
                'calc(((100% - ' +
                gapSize * amountOfGaps +
                'px) / ' +
                amountOfRows +
                ') * ' +
                part.y +
                ' + ' +
                gapSize * amountOfGapsCovered +
                'px)'
            );
        }
        return yPosition;
    }

    prepareForAction() {
        return {
            id: this.id,
            selectorId: this.selectorId,
            containerId: this.containerId,
            secondaryContainerId: this.secondaryContainerId,
            detail: this.detail,
            style: this.style,
            actionLinks: this.actionLinks,
            hidden: this.hidden,
            positions: this.positions,
        };
    }

    public static prepareForAction(part: Part) {
        return {
            id: part.id,
            selectorId: part.selectorId,
            containerId: part.containerId,
            secondaryContainerId: part.secondaryContainerId,
            detail: part.detail,
            style: part.style,
            actionLinks: part.actionLinks,
            hidden: part.hidden,
            positions: part.positions,
        };
    }
}
