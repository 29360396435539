export interface GridStyleDto {
    gridColumns: number;
    gridColumnSize?: number;
    gridColumnGapSize: number;
    gridColumnColor: string;

    gridRows: number;
    gridRowSize?: number;
    gridRowGapSize: number;
    gridRowColor: string;

    snapStrength: number;
    showGrid: boolean;
}
