import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, PartDetail, TemplateVersion } from '@frontoffice/data-access/template';
import { CheckboxFieldPartChoice, CheckboxFieldPartDetail, replaceArguments } from '../../model/checkbox-field-part.detail';
import { CheckboxFieldPartStyle } from '../../model/checkbox-field-part.style';
import { FormBuilder } from '@angular/forms';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { FormField } from '../../../form-field/form-field.component';
import { Subscription } from 'rxjs';
import { NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'app-checkbox-field-part-front',
    templateUrl: './checkbox-field-part-front.component.html',
    styleUrls: ['./checkbox-field-part-front.component.scss'],
})
export class CheckboxFieldPartFrontComponent extends FormField implements ComponentFront, OnInit, OnChanges, OnDestroy {
    partDetail: CheckboxFieldPartDetail = null;
    partStyle: CheckboxFieldPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    answerControlName: string;

    allSelected = false;

    subscriptions: Subscription = new Subscription();

    touched: boolean = false;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        fb: FormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.change();
    }

    ngOnDestroy(): void {
        this.destroy();
    }

    onChangeAnswers(choice: CheckboxFieldPartChoice, checked: boolean): void {
        this.touched = true;
        if (!this.partDetail.answers) {
            this.partDetail.answers = [];
        }
        if (choice.code) {
            if (checked) {
                if (this.partDetail.answers.indexOf(choice.code) === -1) {
                    this.partDetail.answers.push(choice.code);
                }
            } else {
                this.partDetail.answers.splice(this.partDetail.answers.indexOf(choice.code), 1);
            }
            this.executeOnChangeAction();
        }
    }

    onToggleAll(checked: boolean) {
        this.allSelected = checked;
        if (this.partDetail.choices && this.partDetail.choices.length > 0) {
            if (this.allSelected) {
                this.partDetail.choices.forEach(choice => this.onChangeAnswers(choice, true));
            } else {
                this.partDetail.choices.forEach(choice => this.onChangeAnswers(choice, false));
            }
        }
    }

    executeOnChangeAction() {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CHANGE');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks: actionLinks,
                arguments: templateActionArguments,
            });
        }
    }

    override getSubscriptions(): Subscription {
        return this.subscriptions;
    }
    override replaceArguments(): Promise<void> {
        return replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    override getValue(): any {
        return this.partDetail.answers;
    }

    override setValue(value: any): void {
        this.partDetail.answers = value;
        this.touched = true;
    }

    override getPartDetail(): PartDetail {
        return this.partDetail;
    }

    override getCode(): string {
        return this.partDetail.code;
    }
    override getType(): string {
        return this.partDetail.partType;
    }
    override getIdentifier(): string | undefined {
        return this.part.instanceIdentifier;
    }
    override isRequired(): boolean {
        return this.partDetail.required;
    }
    override isEnabled(): boolean {
        return this.partDetail.enabled;
    }
    override getValidationRegex(): string | null {
        return null;
    }
    override getInvalidMessage(): string | null {
        return null;
    }
    override setInvalidMessage(invalidMessage: string | null): void {
        // Do nothing
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
