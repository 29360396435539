<div *ngIf="partDetail" [id]="'id-' + part.id" class="part-content">
    <mat-form-field appearance="outline" data-name="form-code" subscriptSizing="dynamic">
        <mat-label>{{ partDetail.label }}</mat-label>
        <mat-chip-grid #chipList aria-label="Chip selection" [formControl]="myControl">
            <mat-chip-row *ngFor="let chip of chips" (removed)="removeChip(chip)">
                {{ chip }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
        </mat-chip-grid>
        <input
            matInput
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addChip($event)"
            [readonly]="!this.partDetail.enabled" />
        <mat-error
            *ngIf="
                partDetail.required &&
                parentFormGroup.controls[this.answerControlName].touched &&
                parentFormGroup.controls[this.answerControlName].invalid &&
                parentFormGroup.controls[this.answerControlName].hasError('required')
            "
            >{{ partDetail.requiredMessage }}
        </mat-error>
        <mat-icon
            class="help-icon"
            color="primary"
            *ngIf="partDetail.help"
            matSuffix
            [showTrigger]="NgxFloatUiTriggers.hover"
            appendTo="body"
            [floatUi]="questionInformationPopover"
            >help
        </mat-icon>
        <float-ui-content #questionInformationPopover>
            <div class="popover">
                <div [innerHTML]="partDetail.helpInformation"></div>
            </div>
        </float-ui-content>
    </mat-form-field>
</div>
