<div
    *ngIf="partDetail && parentFormGroup && this.answerControlName"
    [formGroup]="parentFormGroup"
    [id]="'id-' + part.id"
    class="part-content">
    <div
        *ngIf="
            partDetail.required && parentFormGroup.controls[this.answerControlName].touched && parentFormGroup.controls['answer'].invalid
        "
        class="error">
        <span>{{ partDetail.requiredMessage }}</span>
    </div>
    <div class="question" dataFormat-name="form-component-question">
        <span
            >{{ partDetail.label }} <ng-container *ngIf="partDetail.required">*</ng-container
            ><mat-icon
                dataFormat-name="form-component-description"
                color="primary"
                class="help-icon"
                *ngIf="partDetail.help"
                [showTrigger]="NgxFloatUiTriggers.hover"
                appendTo="body"
                [floatUi]="questionInformationPopover"
                >help</mat-icon
            >
        </span>
        <float-ui-content #questionInformationPopover>
            <div class="popover">
                <div [innerHTML]="partDetail.helpInformation"></div>
            </div>
        </float-ui-content>
    </div>
    <mat-slider
        [min]="partDetail.min"
        [max]="partDetail.max"
        [step]="partDetail.step"
        [disabled]="!this.partDetail.enabled"
        [discrete]="this.partDetail?.thumb">
        <input matSliderThumb [formControlName]="answerControlName" (change)="onChangeAnswer()" />
    </mat-slider>
</div>
