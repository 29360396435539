import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { Router } from '@angular/router';
import { TemplateFacade } from '../../../../../data-access/template/src/lib/facade/template.facade';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApplicationDto } from '../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { selectApplication } from '../../../../../../../apps/no-code-x-frontoffice/src/app/store/application/application.selectors';
import { frontofficeEnvironment } from '@shared/environment';
import { KeycloakService } from 'keycloak-angular';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../apps/no-code-x-frontoffice/src/app/store/app.state';

@Injectable()
export class RouteToLoginPage implements Method {
    application$: Observable<ApplicationDto> = this.store.select(selectApplication);
    constructor(
        private store: Store<AppState>,
        private keycloak: KeycloakService
    ) {}

    execute(executionScope: ExecutionScope): void {
        this.application$.pipe(take(1)).subscribe(application => {
            const redirectUrl = this.getRedirectUrl(application);
            if (application.loginTemplateId) {
                const loginUrl = this.keycloak.getKeycloakInstance().createLoginUrl({
                    redirectUri: redirectUrl,
                    idpHint: application.forceIdp,
                });
                window.location.href = loginUrl;
            } else {
                this.keycloak.login({
                    redirectUri: redirectUrl,
                    idpHint: application.forceIdp,
                });
            }
        });
    }

    getRedirectUrl(application: ApplicationDto) {
        if (application.loginRedirectPath && application.loginRedirectHost) {
            if (location.host.startsWith('localhost')) {
                return (
                    frontofficeEnvironment.redirectUrl +
                    `?host=${location.protocol}//${location.host}&template=${application.loginRedirectPath}&params=${btoa(location.search)}`
                );
            } else {
                const hostNameParts = location.hostname.split('.');
                const subdomain = hostNameParts.shift();
                return (
                    frontofficeEnvironment.redirectUrl +
                    `?host=${location.protocol}//${application.loginRedirectHost + '.' + hostNameParts.join('.')}&template=${
                        application.loginRedirectPath
                    }&params=${btoa(location.search)}`
                );
            }
        } else {
            return (
                frontofficeEnvironment.redirectUrl +
                `?host=${location.protocol}//${location.host}&template=${location.pathname}&params=${btoa(location.search)}`
            );
        }
    }
}
