<mat-stepper
    *ngIf="partDetail && stepsInitialized"
    [orientation]="(stepperOrientation | async)!"
    linear
    #stepper
    class="subcontainer part-content"
    [id]="'id-' + part.id"
    [selectedIndex]="this.partDetail.openedStep"
    (selectionChange)="onStepOpened($event)"
    (animationDone)="this.initResizeObservers()">
    <ng-container *ngFor="let step of partDetail.steps; index as i; trackBy: identifyStep">
        <ng-template #stepTemplate let-data="step">
            <div class="secondarycontainer" *ngIf="this.openedSteps.has(i)">
                <div class="stepper-controls stepper-controls-top">
                    <button
                        mat-button
                        preventDoubleClick
                        [disabledByPart]="false"
                        (click)="executeOnPreviousAction(step?.code, partDetail.steps[i - 1]?.code)"
                        *ngIf="step.previousButtonLabel"
                        matStepperPrevious>
                        {{ step.previousButtonLabel }}
                    </button>
                    <button
                        mat-button
                        preventDoubleClick
                        [disabledByPart]="false"
                        (click)="executeOnSaveAction(step?.code)"
                        *ngIf="step.saveButtonLabel">
                        {{ step.saveButtonLabel }}
                    </button>
                    <button
                        mat-button
                        preventDoubleClick
                        [disabledByPart]="false"
                        (click)="executeOnNextAction(step?.code, partDetail.steps[i + 1]?.code)"
                        *ngIf="step.nextButtonLabel"
                        matStepperNext>
                        {{ step.nextButtonLabel }}
                    </button>
                </div>
                <ng-container *ngFor="let child of step.parts; trackBy: identifyPart">
                    <ng-container *ngIf="!child.hidden">
                        <stepper-part
                            [attr.id]="'part-' + child.id"
                            [part]="child"
                            [partContainer]="this.part"
                            [host]="host"
                            [templateVersion]="templateVersion"
                            [application]="application"
                            [parentFormGroup]="parentFormGroup"
                            (executeAction)="executeAction.emit($event)">
                        </stepper-part>
                    </ng-container>
                </ng-container>
                <div class="stepper-controls stepper-controls-bottom">
                    <button
                        mat-button
                        preventDoubleClick
                        [disabledByPart]="false"
                        (click)="executeOnPreviousAction(step?.code, partDetail.steps[i - 1]?.code)"
                        *ngIf="step.previousButtonLabel"
                        matStepperPrevious>
                        {{ step.previousButtonLabel }}
                    </button>
                    <button
                        mat-button
                        preventDoubleClick
                        [disabledByPart]="false"
                        (click)="executeOnSaveAction(step?.code)"
                        *ngIf="step.saveButtonLabel">
                        {{ step.saveButtonLabel }}
                    </button>
                    <button
                        mat-button
                        preventDoubleClick
                        [disabledByPart]="false"
                        (click)="executeOnNextAction(step?.code, partDetail.steps[i + 1]?.code)"
                        *ngIf="step.nextButtonLabel"
                        matStepperNext>
                        {{ step.nextButtonLabel }}
                    </button>
                </div>
            </div>
        </ng-template>

        <mat-step *ngIf="step.icon" [label]="step.name" [state]="step.icon ? step.icon : null">
            <ng-container *ngTemplateOutlet="stepTemplate; context: { $implicit: step }"></ng-container>
        </mat-step>
        <mat-step *ngIf="!step.icon" [label]="step.name">
            <ng-container *ngTemplateOutlet="stepTemplate; context: { $implicit: step }"></ng-container>
        </mat-step>
    </ng-container>
</mat-stepper>
