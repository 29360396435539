import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Part } from '@frontoffice/data-access/template';
import { PartState } from './part.state';
import { fetchPartSuccess, removePartInstanceFromStore, updatePartsSuccess } from './part.actions';

const adapterPart = createEntityAdapter<Part>({
    selectId: (entity: Part) => {
        return entity.instanceIdentifier;
    },
});
export const partInitialState: PartState = adapterPart.getInitialState();
export const partReducer = (initialData: PartState) =>
    createReducer(
        initialData,
        on(fetchPartSuccess, (state, { parts }) => adapterPart.upsertMany(parts, state)),
        on(updatePartsSuccess, (state, { parts }) => adapterPart.upsertMany(parts, state)),
        on(removePartInstanceFromStore, (state, { instanceIdentifier }) => {
            return adapterPart.removeOne(instanceIdentifier, state);
        })
    );
