import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { plainToInstance } from 'class-transformer';
import { map } from 'rxjs/operators';
import { ParamMap } from '@angular/router';
import * as hash from 'object-hash';
import { TemplateVersionResponse } from '../models/template-version-response.model';
import { TemplateArgument } from '../models/template-argument.model';
import { TemplateVersionRequest } from '../models/template-version-request.interface';

@Injectable({
    providedIn: 'root',
})
export class TemplateService {
    constructor(private httpClient: HttpClient) {}

    getTemplateVersion(
        host: string | null,
        path: string,
        applicationId: string,
        companyId: string,
        onEntryOnBackend: boolean = true
    ): Observable<TemplateVersionResponse> {
        const params = new HttpParams()
            .set('applicationId', applicationId)
            .set('companyId', companyId)
            .set('onEntryOnBackend', onEntryOnBackend)
            .set('hc', !onEntryOnBackend);
        const purePath = path.split('#')[0];
        return this.httpClient
            .get<TemplateVersionResponse>(`${host}/v2/template-version${purePath}`, { params: params })
            .pipe(map(templateVersionResponse => plainToInstance(TemplateVersionResponse, templateVersionResponse)));
    }

    getTemplateVersionById(
        host: string,
        templateId: string,
        applicationId: string,
        companyId: string
    ): Observable<TemplateVersionResponse> {
        const params = new HttpParams().set('templateId', templateId).set('applicationId', applicationId).set('companyId', companyId);
        return this.httpClient
            .get<TemplateVersionResponse>(`${host}/v2/template-version`, { params: params })
            .pipe(map(templateVersionResponse => plainToInstance(TemplateVersionResponse, templateVersionResponse)));
    }

    getTemplateVersionByIdAndParams(
        host: string,
        templateId: string,
        applicationId: string,
        companyId: string,
        args: TemplateArgument[]
    ): Observable<TemplateVersionResponse> {
        let params = new HttpParams().set('templateId', templateId).set('applicationId', applicationId).set('companyId', companyId);
        if (templateId && applicationId && companyId) {
            if (!!args) {
                args.forEach(argument => {
                    if (argument && argument.calculatedValue) {
                        params = params.append(argument.parameterId, argument.calculatedValue);
                    }
                });
            }
            return this.httpClient
                .get<TemplateVersionResponse>(`${host}/v2/template-version`, { params: params })
                .pipe(map(templateVersionResponse => plainToInstance(TemplateVersionResponse, templateVersionResponse)));
        } else {
            return of(null);
        }
    }

    getTemplateVersionByIdAndArgumentsAndParams(
        host: string,
        templateId: string,
        applicationId: string,
        companyId: string,
        args: TemplateArgument[],
        paramMap: ParamMap,
        hardCache: boolean = true,
        onEntryOnBackend: boolean = false,
        languageCode?: string | null
    ): Observable<TemplateVersionResponse> {
        if (templateId && applicationId && companyId) {
            if (!args) {
                args = [];
            }
            //always pass companyId as param, since its being used to calculate the realm.
            let params = new HttpParams()
                .set('companyId', companyId)
                .set('ca', hash(templateId))
                .set('onEntryOnBackend', onEntryOnBackend)
                .set('hc', true);
            if (paramMap) {
                paramMap.keys.forEach(paramName => {
                    const existingArgument = args.find(argument => argument.name === paramName);
                    if (!existingArgument) {
                        args.push({
                            value: paramMap.get(paramName),
                            calculatedValue: paramMap.get(paramName),
                            name: paramName,
                        });
                    }
                });
            }
            const templateVersionRequest: TemplateVersionRequest = {
                applicationId: applicationId,
                companyId: companyId,
                templateId: templateId,
                arguments: args,
                executeEntryActions: onEntryOnBackend,
                languageCode: languageCode,
            };
            return this.httpClient
                .post<TemplateVersionResponse>(`${host}/v2/template-version`, templateVersionRequest, {
                    params: params,
                    headers: new HttpHeaders().set('X-Skip-404-Error-Interceptor', 'true'),
                })
                .pipe(map(templateVersionResponse => plainToInstance(TemplateVersionResponse, templateVersionResponse)));
        } else {
            return of(null);
        }
    }
}
