import { ModuleWithProviders } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '**',
        loadChildren: () => import('./templateversion/templateversion.module').then(m => m.TemplateversionModule),
        runGuardsAndResolvers: 'always',
    },
];

export const appRoutingModule: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
});

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    handlers: { [key: string]: DetachedRouteHandle } = {};

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.data.shouldReuse || false;
    }

    store(route: ActivatedRouteSnapshot, handle: {}): void {
        if (route.data.shouldReuse) {
            this.handlers[route.routeConfig.path] = handle;
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
    }

    retrieve(route: ActivatedRouteSnapshot): {} {
        if (!route.routeConfig) return null;
        return this.handlers[route.routeConfig.path];
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.data.shouldReuse || false;
    }
}
