import { TemplateversionDialogComponent } from '../../../../../../../apps/no-code-x-frontoffice/src/app/templateversion-dialog/templateversion-dialog.component';
import { switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActionService } from '@frontoffice/data-access/template';

@Injectable()
export class ShowDialog implements Method {
    constructor(
        public dialog: MatDialog,
        private actionService: ActionService
    ) {}

    execute(executionScope: ExecutionScope): void {
        const width: string = executionScope.getOptionalVariable('width');
        const maxWidth: string = executionScope.getOptionalVariable('maxWidth');
        const minWidth: string = executionScope.getOptionalVariable('minWidth');
        const height: string = executionScope.getOptionalVariable('height');
        const maxHeight: string = executionScope.getOptionalVariable('maxHeight');
        const minHeight: string = executionScope.getOptionalVariable('minHeight');
        const disableClose: boolean = !executionScope.getOptionalVariable('allowcloseclickoutside', true);
        const closeOnNavigation: boolean = executionScope.getOptionalVariable('closeonnavigation', true);
        const enterAnimationDuration: number = executionScope.getOptionalVariable('enterAnimationDuration');
        const exitAnimationDuration: number = executionScope.getOptionalVariable('exitAnimationDuration');
        const hasBackdrop: boolean = executionScope.getOptionalVariable('hasBackdrop', true);
        const topPosition: string = executionScope.getOptionalVariable('topPosition');
        const bottomPosition: string = executionScope.getOptionalVariable('bottomPosition');
        const leftPosition: string = executionScope.getOptionalVariable('leftPosition');
        const rightPosition: string = executionScope.getOptionalVariable('rightPosition');
        const scrollStrategy: string = executionScope.getOptionalVariable('scrollStrategy');
        const templateId: string = executionScope.getRequiredVariable('templateId');
        const templateArguments: any = executionScope.getRequiredVariable('templateParams');
        const afterCloseAction = executionScope.getOptionalVariable('aftercloseaction');
        const dialogRefs: MatDialogRef<any>[] = executionScope.getRequiredVariable('dialogRefs');

        const dialogConfig = {
            width: width,
            maxWidth: maxWidth,
            minWidth: minWidth,
            height: height,
            maxHeight: maxHeight,
            minHeight: minHeight,
            disableClose: disableClose,
            closeOnNavigation: closeOnNavigation,
            enterAnimationDuration: enterAnimationDuration,
            exitAnimationDuration: exitAnimationDuration,
            hasBackdrop: hasBackdrop,
            position: {
                top: topPosition,
                bottom: bottomPosition,
                left: leftPosition,
                right: rightPosition,
            },
            data: {
                templateId: templateId,
                templateArguments: templateArguments,
                parentTemplateInstanceIdentifier: executionScope.getRequiredVariable('template').instanceIdentifier,
                dialogRefs: dialogRefs,
            },
        };
        const dialogRef = this.dialog.open(TemplateversionDialogComponent, dialogConfig);

        dialogRefs.push(dialogRef);

        if (!!afterCloseAction) {
            dialogRef
                .afterClosed()
                .pipe(
                    take(1),
                    switchMap(result => {
                        return this.actionService.createActionExecution(
                            executionScope.getRequiredVariable('host'),
                            executionScope.getRequiredVariable('application').companyId,
                            Object.assign(
                                {
                                    currentUrl: {
                                        path: window.location.pathname,
                                        domain: window.location.host,
                                    },
                                    browser: {
                                        language: navigator.language,
                                        online: navigator.onLine,
                                        userAgent: navigator.userAgent,
                                    },
                                },
                                afterCloseAction
                            )
                        );
                    })
                )
                .subscribe(result => {
                    executionScope.getRequiredVariable('handleActionExecutionResult')(result, {
                        executionResultPartId: executionScope.getRequiredVariable('executionResultPartId'),
                    });
                });
        }
    }
}
