import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { StepperPartDetail } from '../../model/stepper-part.detail';
import { StepperPartStyle } from '../../model/stepper-part.style';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { StepperOrientation, StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject, debounceTime, map, Observable, Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    getClosestPartPositioning,
    PartPositioning,
} from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-positioning.dto';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

declare let jQuery: any;

@Component({
    selector: 'app-stepper-part-front',
    templateUrl: './stepper-part-front.component.html',
    styleUrls: ['./stepper-part-front.component.scss'],
})
export class StepperPartFrontComponent implements ComponentFront, OnInit, OnChanges, AfterViewInit {
    partDetail: StepperPartDetail = null;
    partStyle: StepperPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    selectedPart: Part = null;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    offsetHeight: number = Math.round((72 + 7 + 1) / (this.window.innerWidth / 100));

    parentFormGroup: FormGroup;

    host = '';

    stepsInitialized = false;

    openedStep = 0;

    openedSteps: Set<number> = new Set();

    @ViewChild('stepper') private theStepper: MatStepper;

    stepperOrientation: Observable<StepperOrientation>;

    resizeObserver: ResizeObserver;

    mutationObserver: MutationObserver;

    private resizePart: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    subscriptions: Subscription = new Subscription();

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        breakpointObserver: BreakpointObserver,
        public window: Window
    ) {
        this.stepperOrientation = breakpointObserver
            .observe('(min-width: 800px)')
            .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
    }

    ngOnInit(): void {
        this.partDetail.openedStep = 0;
        this.initPartsOnSteps();
        this.initOpenedStep();
        this.subscriptions.add(
            this.resizePart.pipe(debounceTime(100)).subscribe(resizeNumber => {
                this.adjustHeightOfSubContainer(this.openedStep);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initPartsOnSteps();
        this.initOpenedStep();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
        }
    }

    ngAfterViewInit(): void {
        this.initResizeObservers();
    }

    initResizeObservers() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
        }

        const elements = document.querySelectorAll(
            '#id-' +
                this.part?.id +
                ' > div.mat-horizontal-stepper-wrapper > div.mat-horizontal-content-container > div.mat-horizontal-stepper-content > .secondarycontainer > stepper-part'
        );

        if (elements) {
            this.resizeObserver = new ResizeObserver(entries => {
                this.resizePart.next(this.resizePart.value + 1);
            });
            elements.forEach(element => {
                this.resizeObserver.observe(element);
            });

            this.mutationObserver = new MutationObserver(() => {
                this.resizePart.next(this.resizePart.value + 1);
            });
            this.mutationObserver.observe(document.querySelector('#id-' + this.part?.id), {
                childList: true,
                attributes: false,
                subtree: true,
            });
        }
    }

    initOpenedStep(): void {
        if (this.partDetail.openedStep || this.partDetail.openedStep === 0) {
            if (this.parentFormGroup.valid || this.openedStep > this.partDetail.openedStep) {
                this.theStepper.selectedIndex = this.partDetail.openedStep;
                this.openedStep = this.partDetail.openedStep;
                this.openedSteps.clear();
                this.openedSteps.add(this.partDetail.openedStep);
            } else {
                this.partDetail.openedStep = this.openedStep;
            }
        } else {
            this.partDetail.openedStep = 0;
            this.openedStep = 0;
            this.openedSteps.add(0);
        }
    }

    adjustHeightOfSubContainer(step: number): void {
        if (step || step === 0) {
            step = step + 1;
            const partPositioning: PartPositioning = getClosestPartPositioning(window.innerWidth, window.innerHeight, this.part.positions);
            if (partPositioning.sizeYUnit === 'fit-content') {
                let highestHeight = 0;
                const parent = jQuery(
                    '#id-' +
                        this.part.id +
                        ' > div.mat-horizontal-stepper-wrapper > div.mat-horizontal-content-container > div.mat-horizontal-stepper-content:nth-child(' +
                        step +
                        ') > .secondarycontainer'
                );
                const listItems = jQuery(
                    '#id-' +
                        this.part.id +
                        ' > div.mat-horizontal-stepper-wrapper > div.mat-horizontal-content-container > div.mat-horizontal-stepper-content:nth-child(' +
                        step +
                        ') .secondarycontainer > stepper-part'
                );
                for (let i = 0; i < listItems.length; i++) {
                    const content = listItems.eq(i);
                    const height = content.get(0).getBoundingClientRect().height;
                    const top: number = content.get(0).getBoundingClientRect().top - parent.get(0).getBoundingClientRect().top;
                    if (height + top > highestHeight) {
                        highestHeight = height + top;
                    }
                }
                parent.height(highestHeight);
                this.changeDetectorRef.detectChanges();
            }

            if (partPositioning.sizeXUnit === 'fit-content') {
                let highestWidth = 0;
                const parent = jQuery(
                    '#id-' +
                        this.part.id +
                        ' > div.mat-horizontal-stepper-wrapper > div.mat-horizontal-content-container > div.mat-horizontal-stepper-content:nth-child(' +
                        step +
                        ') > .secondarycontainer'
                );
                const listItems = jQuery(
                    '#id-' +
                        this.part.id +
                        ' > div.mat-horizontal-stepper-wrapper > div.mat-horizontal-content-container > div.mat-horizontal-stepper-content:nth-child(' +
                        step +
                        ') .secondarycontainer > stepper-part'
                );
                for (let i = 0; i < listItems.length; i++) {
                    const content = listItems.eq(i);
                    const width = content.get(0).getBoundingClientRect().width;
                    const left: number = content.get(0).getBoundingClientRect().left - parent.get(0).getBoundingClientRect().left;
                    if (width + left > highestWidth) {
                        highestWidth = width + left;
                    }
                }
                parent.width(highestWidth);
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    onClickHeader($event) {
        console.log($event);
    }

    onStepOpened($event: StepperSelectionEvent): void {
        this.openedStep = $event.selectedIndex;
        this.partDetail.openedStep = $event.selectedIndex;
        this.openedSteps.clear();
        this.openedSteps.add($event.selectedIndex);
        if (this.partDetail.steps[$event.previouslySelectedIndex]) {
            this.executeOnSaveAction(this.partDetail.steps[$event.previouslySelectedIndex].code);
        }
    }

    executeOnNextAction(previousStepCode: string, nextStepCode: string): void {
        if (this.parentFormGroup.valid) {
            const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
                return {
                    name: templateArgument.name,
                    value: templateArgument.value,
                    calculatedValue: templateArgument.calculatedValue,
                    subArguments: templateArgument.subArguments,
                };
            });
            templateActionArguments.push(
                {
                    name: 'PREVIOUS_STEP',
                    value: previousStepCode,
                    calculatedValue: previousStepCode,
                    subArguments: undefined,
                },
                {
                    name: 'NEXT_STEP',
                    value: nextStepCode,
                    calculatedValue: nextStepCode,
                    subArguments: undefined,
                },
                {
                    name: 'STEP_DIRECTION',
                    value: 'FORWARD',
                    calculatedValue: 'FORWARD',
                    subArguments: undefined,
                }
            );
            const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CLICK_NEXT');
            if (!!actionLinks && actionLinks.length > 0) {
                this.executeAction.emit({
                    trigger: this.part.id,
                    actionLinks: actionLinks,
                    arguments: templateActionArguments,
                });
            }
        } else {
            this.parentFormGroup.markAllAsTouched();
        }
    }

    executeOnSaveAction(currentStep: string): void {
        if (this.parentFormGroup.valid) {
            const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
                return {
                    name: templateArgument.name,
                    value: templateArgument.value,
                    calculatedValue: templateArgument.calculatedValue,
                    subArguments: templateArgument.subArguments,
                };
            });
            templateActionArguments.push({
                name: 'CURRENT_STEP',
                value: currentStep,
                calculatedValue: currentStep,
                subArguments: undefined,
            });
            const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_STEPPER_SAVE');
            if (!!actionLinks && actionLinks.length > 0) {
                this.executeAction.emit({
                    trigger: this.part.id,
                    actionLinks: actionLinks,
                    arguments: templateActionArguments,
                });
            }
        } else {
            this.parentFormGroup.markAllAsTouched();
        }
    }

    executeOnPreviousAction(previousStepCode: string, nextStepCode: string): void {
        if (this.parentFormGroup.valid) {
            const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
                return {
                    name: templateArgument.name,
                    value: templateArgument.value,
                    calculatedValue: templateArgument.calculatedValue,
                    subArguments: templateArgument.subArguments,
                };
            });
            templateActionArguments.push(
                {
                    name: 'PREVIOUS_STEP',
                    value: previousStepCode,
                    calculatedValue: previousStepCode,
                    subArguments: undefined,
                },
                {
                    name: 'NEXT_STEP',
                    value: nextStepCode,
                    calculatedValue: nextStepCode,
                    subArguments: undefined,
                },
                {
                    name: 'STEP_DIRECTION',
                    value: 'BACKWARDS',
                    calculatedValue: 'BACKWARDS',
                    subArguments: undefined,
                }
            );
            const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CLICK_PREVIOUS');
            if (!!actionLinks && actionLinks.length > 0) {
                this.executeAction.emit({
                    trigger: this.part.id,
                    actionLinks: actionLinks,
                    arguments: templateActionArguments,
                });
            }
        } else {
            this.parentFormGroup.markAllAsTouched();
        }
    }

    identifyPart(index, item) {
        // Adding templateID to identify this part is a very hacky way to make sure template parts should be refreshed if
        // They have the same selectorId but a different template configured.
        // This can happen when a user copies a page & then changes the template in the copied page.
        // Without templateID in this return value, hopping from the copied page to the original page & back will not result in changing the content of
        // the template component.
        return item.selectorId + item.detail['templateId'];
    }

    identifyStep(index, item) {
        return index;
    }

    initPartsOnSteps(): void {
        this.partDetail?.steps?.forEach(step => (step.parts = []));
        this.partDetail?.parts?.forEach(part => {
            let step = this.partDetail.steps.find(step => step.id === part.secondaryContainerId);
            if (!step) {
                step = this.partDetail.steps.find(step => step.code === part.secondaryContainerId);
            }
            if (step) {
                step.parts.push(part);
            }
        });
        this.stepsInitialized = true;
        this.changeDetectorRef.detectChanges();

        setTimeout(() => {
            this.theStepper.steps.forEach((step, idx) => {
                step.select = () => {
                    if (
                        (this.partDetail.checkFormValidityOnStepChange && this.parentFormGroup.valid) ||
                        !this.partDetail.checkFormValidityOnStepChange
                    ) {
                        this.partDetail.openedStep = idx;
                        step.hasError = false;
                    } else if (this.partDetail.checkFormValidityOnStepChange && !this.parentFormGroup.valid) {
                        this.parentFormGroup.markAllAsTouched();
                        step.hasError = true;
                    }
                };
            });
        });
    }
}
