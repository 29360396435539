import { Part } from './part.model';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PartDetail {
    public partType: string;
    public code?: string;
    public parts?: Part[];

    public isValid(): boolean {
        return false;
    }

    public addContainerPart(part: Part): void {}

    public getChildParts(): Part[] {
        return [];
    }
}

export function createInvalidMessageValidator(partDetailFunction): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const partDetail = partDetailFunction.call();
        return !!partDetail['invalidMessage'] ? { invalidmessage: true } : null;
    };
}
