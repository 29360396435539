import { InvocationDto } from './invocation.dto';
import { ApplicationDto } from '../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { MatDialogRef } from '@angular/material/dialog';
import { TemplateVersion } from '@frontoffice/data-access/template';

export class ExecutionScope {
    scope: Map<string, any> = new Map();
    innerScope: Map<string, any> = new Map();

    private getValueFromScope(name: string): any | undefined {
        if (this.innerScope.has(name) && this.innerScope.get(name)) {
            return this.innerScope.get(name);
        } else if (this.scope.has(name) && this.scope.get(name)) {
            return this.scope.get(name);
        } else {
            return undefined;
        }
    }
    public getRequiredVariable(name: string) {
        return this.getValueFromScope(name);
    }

    public getOptionalVariable(name: string, defaultValue?: any) {
        const value = this.getValueFromScope(name);
        if (value) {
            return value;
        } else {
            return defaultValue;
        }
    }

    public createLexicalScope(invocation: InvocationDto) {
        if (invocation.arguments && invocation.arguments.length > 0) {
            invocation.arguments.forEach(argument => {
                this.innerScope.set(argument.name, argument.value);
            });
        }
    }

    public destroyLexicalScope(invocation: InvocationDto) {
        this.innerScope.clear();
    }

    public createInitialScope(
        templateVersion: TemplateVersion,
        application: ApplicationDto,
        host: string | null,
        dialogRefs: MatDialogRef<any>[],
        handleActionExecutionResult: any,
        executionResultPartId: string,
        webComponent: boolean
    ) {
        this.scope.set('template', templateVersion);
        this.scope.set('application', application);
        this.scope.set('dialogRefs', dialogRefs);
        this.scope.set('host', host);
        this.scope.set('handleActionExecutionResult', handleActionExecutionResult);
        this.scope.set('executionResultPartId', executionResultPartId);
        this.scope.set('webComponent', webComponent);
    }
}
