import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { HttpClient } from '@angular/common/http';
import FileSaver from 'file-saver';

@Injectable()
export class OpenMedia implements Method {
    constructor(private readonly httpClient: HttpClient) {}

    execute(executionScope: ExecutionScope): void {
        const host = executionScope.getRequiredVariable('host');
        const mediaId = executionScope.getRequiredVariable('media_id');
        window.open(`${host}/v2/media/file/${mediaId}`, '_blank');
    }
}
