import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitlePartFrontComponent } from './components/title-part-front/title-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [TitlePartFrontComponent],
    imports: [CommonModule, MatTooltipModule],
})
export class TitlePartModule {
    constructor() {
        getPartDetailComponents().set('title', TitlePartFrontComponent);
    }
}
