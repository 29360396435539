import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable()
export class ShowSnackbar implements Method {
    constructor(private snackBar: MatSnackBar) {}

    execute(executionScope: ExecutionScope): void {
        const text: string = executionScope.getRequiredVariable('text');
        const duration: number = executionScope.getRequiredVariable('activetime');
        const horizontalPosition: MatSnackBarHorizontalPosition = executionScope.getOptionalVariable('horizontalPosition', 'center');
        const verticalPosition: MatSnackBarVerticalPosition = executionScope.getOptionalVariable('verticalPosition', 'bottom');
        this.snackBar.open(text, undefined, {
            duration: duration,
            horizontalPosition: horizontalPosition,
            verticalPosition: verticalPosition,
        });
    }
}
