import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideToggleFieldPartFrontComponent } from './components/slide-toggle-field-part-front/slide-toggle-field-part-front.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { getPartDetailComponents } from '../../../part-module';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [SlideToggleFieldPartFrontComponent],
    imports: [CommonModule, MatSlideToggleModule, NgxFloatUiModule, FormsModule, ReactiveFormsModule, MatIconModule],
})
export class SlideToggleFieldPartModule {
    constructor() {
        getPartDetailComponents().set('slide-toggle', SlideToggleFieldPartFrontComponent);
    }
}
