import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextpartdetailComponent } from './components/textpartdetail/textpartdetail.component';
import { getPartDetailComponents } from '../../../part-module';

@NgModule({
    declarations: [TextpartdetailComponent],
    imports: [CommonModule],
})
export class TextpartModule {
    constructor() {
        getPartDetailComponents().set('text', TextpartdetailComponent);
    }
}
