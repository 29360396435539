import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { HttpClient } from '@angular/common/http';
import FileSaver from 'file-saver';

@Injectable()
export class DownloadMedia implements Method {
    constructor(private readonly httpClient: HttpClient) {}

    execute(executionScope: ExecutionScope): void {
        const host = executionScope.getRequiredVariable('host');
        const mediaId = executionScope.getRequiredVariable('media_id');
        const fileName = executionScope.getRequiredVariable('file_name');
        this.httpClient
            .get(`${host}/v2/media/file/${mediaId}`, {
                responseType: 'blob',
            })
            .subscribe(response => {
                const { size, type } = response;
                FileSaver.saveAs(response, fileName);
            });
    }
}
