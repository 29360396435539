import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class HideDialog implements Method {
    execute(executionScope: ExecutionScope): void {
        const dialogRefs: MatDialogRef<any>[] = executionScope.getRequiredVariable('dialogRefs');
        if (dialogRefs && dialogRefs.length > 0) {
            const dialogRef = dialogRefs.pop();
            if (dialogRef) {
                dialogRef.close();
            }
        }
    }
}
