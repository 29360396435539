import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export interface CheckboxFieldPartDetail extends PartDetail {
    code: string;
    label: string;
    processedLabel: string;

    required: boolean;
    requiredMessage: string;
    processedRequiredMessage: string;

    help: boolean;
    helpInformation: string;
    processedHelpInformation: string;

    answers: string[];

    choices: CheckboxFieldPartChoice[];

    enabled: boolean;

    addSelectAll: boolean;
    selectAllText: string | null;
}

export async function replaceArguments(detail: CheckboxFieldPartDetail, templateArguments: TemplateArgument[]) {
    detail.processedLabel = await replaceArgumentsForString(detail.label, templateArguments);
    detail.processedRequiredMessage = await replaceArgumentsForString(detail.requiredMessage, templateArguments);
    detail.processedHelpInformation = await replaceArgumentsForString(detail.helpInformation, templateArguments);
}

export interface CheckboxFieldPartChoice {
    id?: string;
    code?: string;
    label?: string;
    selected?: boolean;

    help?: boolean;
    helpInformation?: string;
}
