import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    fetchTemplateByHostSuccess,
    fetchTemplateByIdSuccess,
    updateTemplateById,
    updateTemplateByPath,
} from '../template/template.action';
import { mergeMap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Part, TemplateService, TemplateVersion } from '@frontoffice/data-access/template';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { updatePartsSuccess } from './part.actions';
import { of } from 'rxjs';

@Injectable()
export class PartEffects {
    constructor(
        private templateService: TemplateService,
        protected readonly actions$: Actions,
        @Inject(DOCUMENT) private document: Document,
        private readonly store: Store<AppState>
    ) {}

    initializeParts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchTemplateByIdSuccess, fetchTemplateByHostSuccess),
            mergeMap(({ templateVersionResponse }) => {
                return of(updatePartsSuccess({ parts: this.createListOfParts(templateVersionResponse.templateVersion) }));
            })
        )
    );

    updateParts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateTemplateById, updateTemplateByPath),
            mergeMap(template => {
                return of(updatePartsSuccess({ parts: this.createListOfParts(template.templateVersion) }));
            })
        )
    );

    private createListOfParts(templateVersion: TemplateVersion): Part[] {
        const parts: Part[] = [];
        this.addPartsToList(parts, templateVersion.parts, templateVersion.instanceIdentifier);
        return parts;
    }

    private addPartsToList(list: Part[], partsToAdd: Part[], templateInstanceIdentifier: string) {
        partsToAdd.forEach(part => {
            part.instanceIdentifier = templateInstanceIdentifier + '-' + part.selectorId;
            list.push(part);
            if (!!part.detail.parts && part.detail.parts.length > 0) {
                this.addPartsToList(list, part.detail.parts, templateInstanceIdentifier);
            }
        });
    }
}
