import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';
import { ButtonPartDetail } from '../../button-part/model/button-part.detail';

export interface IconButtonPartDetail extends PartDetail {
    code: string;
    tooltip?: string;
    processedTooltip?: string;
    icon: string;
    processedIcon?: string;
    type: string;
    enabled: boolean;
}

export async function replaceArguments(detail: IconButtonPartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    if (detail.tooltip) {
        detail.processedTooltip = await replaceArgumentsForString(detail.tooltip, templateArguments);
    }
    detail.processedIcon = await replaceArgumentsForString(detail.icon, templateArguments);
}
