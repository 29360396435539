<ng-container *ngIf="{ template: this.template$ | async } as observables">
    <template-part-template
        *ngIf="observables.template && parentFormGroup"
        [id]="identifier"
        [identifier]="identifier"
        [formGroupId]="formGroupId"
        [template]="observables.template"
        [application]="application"
        [parentFormGroup]="parentFormGroup"
        [host]="host"
        (executeAction)="onExecuteAction($event)"
        (templateResult)="templateResult.emit($event)">
    </template-part-template>
</ng-container>
