import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsPartFrontComponent } from './components/chips-part-front/chips-part-front.component';
import { MatChipsModule } from '@angular/material/chips';
import { getPartDetailComponents } from '../../../part-module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [ChipsPartFrontComponent],
    imports: [CommonModule, MatChipsModule, MatIconModule],
})
export class ChipsPartModule {
    constructor() {
        getPartDetailComponents().set('chips', ChipsPartFrontComponent);
    }
}
