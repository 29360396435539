<ng-container
    *ngIf="{
        template: template$ | async,
        application: application$ | async
    } as observables">
    <app-template
        *ngIf="observables.template?.templateDto"
        [template]="observables.template"
        [templateIdentifier]="observables.template.instanceIdentifier"
        [application]="observables.application"
        [host]="host"
        [parentFormGroup]="parentFormGroup"
        (executeAction)="onExecuteAction($event)"></app-template>
</ng-container>
