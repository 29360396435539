import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalDividerFrontComponent } from './components/vertical-divider-front/vertical-divider-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
    declarations: [VerticalDividerFrontComponent],
    imports: [CommonModule, MatDividerModule],
})
export class VerticalDividerModule {
    constructor() {
        getPartDetailComponents().set('vertical-divider', VerticalDividerFrontComponent);
    }
}
