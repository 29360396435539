import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { OnSameUrlNavigation, Router } from '@angular/router';
import { TemplateFacade } from '../../../../../data-access/template/src/lib/facade/template.facade';

@Injectable()
export class RouteExternally implements Method {
    constructor() {}
    execute(executionScope: ExecutionScope): void {
        const routeUrl = executionScope.getRequiredVariable('url');
        const target = executionScope.getRequiredVariable('target');
        if (target !== 'BLANK') {
            window.location.href = routeUrl;
        } else {
            window.open(routeUrl, '_blank');
        }
    }
}
