import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownFieldPartFrontComponent } from './components/dropdown-field-part-front/dropdown-field-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [DropdownFieldPartFrontComponent],
    imports: [CommonModule, NgxFloatUiModule, MatIconModule, MatSelectModule, FormsModule, ReactiveFormsModule],
})
export class DropdownFieldPartModule {
    constructor() {
        getPartDetailComponents().set('dropdown-field', DropdownFieldPartFrontComponent);
    }
}
