import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconPartFrontComponent } from './components/icon-part-front/icon-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [IconPartFrontComponent],
    imports: [CommonModule, MatTooltipModule, MatIconModule],
})
export class IconPartModule {
    constructor() {
        getPartDetailComponents().set('icon', IconPartFrontComponent);
    }
}
