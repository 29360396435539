import { RouterModule, Routes } from '@angular/router';
import { TemplateversionComponent } from './pages/templateversion/templateversion.component';

export const templateVersionRoutes: Routes = [
    {
        path: '**',
        component: TemplateversionComponent,
    },
];

export const templateVersionRouting = RouterModule.forChild(templateVersionRoutes);
