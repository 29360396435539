// Angular Directive - Prevent double click for angular 6 material button (6.2)
// using Angular 6, Angular material button: 6.2.1
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

const DISABLE_TIME = 500;

@Directive({
    selector: '[preventDoubleClick]',
})
export class PreventDoubleClickPipe {
    @Input() disabledByPart: boolean;
    constructor(private elementRef: ElementRef) {}

    @HostListener('click', ['$event'])
    clickEvent(event) {
        this.elementRef.nativeElement.setAttribute('disabled', 'true');
        setTimeout(() => {
            if (!this.disabledByPart) {
                this.elementRef?.nativeElement?.removeAttribute('disabled');
            }
        }, DISABLE_TIME);
    }
}
