import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { UsageExceededComponent } from './pages/usage-exceeded/usage-exceeded.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { ErrorComponent } from './pages/error/error.component';

export const statusRoutes: Routes = [
    {
        path: 'nocodex-default-not-found',
        component: NotFoundComponent,
    },
    {
        path: 'nocodex-default-usage-exceeded',
        component: UsageExceededComponent,
    },
    {
        path: 'nocodex-default-forbidden',
        component: ForbiddenComponent,
    },
    {
        path: 'nocodex-default-error',
        component: ErrorComponent,
    },
];

export const statusRouting = RouterModule.forChild(statusRoutes);
