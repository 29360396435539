import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberInputFieldPartFrontComponent } from './components/number-input-field-part-front/number-input-field-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFloatUiModule } from 'ngx-float-ui';

@NgModule({
    declarations: [NumberInputFieldPartFrontComponent],
    imports: [CommonModule, MatIconModule, NgxFloatUiModule, MatInputModule, FormsModule, ReactiveFormsModule],
})
export class NumberInputFieldPartModule {
    constructor() {
        getPartDetailComponents().set('number-input-field', NumberInputFieldPartFrontComponent);
    }
}
