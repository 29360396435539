import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineChartPartFrontComponent } from './components/line-chart-part-front/line-chart-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
    declarations: [LineChartPartFrontComponent],
    imports: [CommonModule, NgxEchartsModule],
})
export class LineChartPartModule {
    constructor() {
        getPartDetailComponents().set('line-chart', LineChartPartFrontComponent);
    }
}
