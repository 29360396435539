import { ChangeDetectorRef, Component, EventEmitter, HostListener, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { ButtonPartDetail, replaceArguments } from '../../model/button-part.detail';
import { ButtonPartStyle } from '../../model/button-part.style';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { ActionExecutionInvocationArgumentDto } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/dto/action-execution-invocation-argument.dto';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'app-button-part-front',
    templateUrl: './button-part-front.component.html',
    styleUrls: ['./button-part-front.component.scss'],
})
export class ButtonPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: ButtonPartDetail = null;
    partStyle: ButtonPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[] | null;
    }>;

    formGroup: FormGroup;

    parentFormGroup: FormGroup;

    @HostListener('document:keydown', ['$event'])
    handleDeleteKeyboardEvent(event: KeyboardEvent) {
        if (this.partDetail && this.partDetail.type === 'submit' && event.key === 'Enter') {
            this.onClick();
        }
    }

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.changeDetectorRef.detectChanges();
        replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    executeOnClickAction(): void {
        const templateActionArguments: ActionExecutionInvocationArgumentDto[] | null = this.templateVersion?.arguments?.map(
            templateArgument => {
                return {
                    name: templateArgument.name,
                    value: templateArgument.value,
                    calculatedValue: templateArgument.calculatedValue,
                    subArguments: templateArgument.subArguments,
                };
            }
        );
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CLICK');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks: actionLinks,
                arguments: templateActionArguments,
            });
        }
    }

    onClick(): void {
        if (this.partDetail.type === 'submit') {
            this.parentFormGroup.markAllAsTouched();
            if (this.parentFormGroup.valid) {
                this.executeOnClickAction();
            }
        } else {
            this.executeOnClickAction();
        }
    }
}
