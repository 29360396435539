import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkPartFrontComponent } from './components/link-part-front/link-part-front.component';
import { getPartDetailComponents } from '../../../part-module';

@NgModule({
    declarations: [LinkPartFrontComponent],
    imports: [CommonModule],
})
export class LinkPartModule {
    constructor() {
        getPartDetailComponents().set('link', LinkPartFrontComponent);
    }
}
