import { frontofficeEnvironment } from '@shared/environment';
import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class Logout implements Method {
    constructor(private keycloakService: KeycloakService) {}

    execute(executionScope: ExecutionScope): void {
        this.keycloakService.logout(
            frontofficeEnvironment.redirectUrl +
                '?host=' +
                window.location.protocol +
                '//' +
                window.location.host +
                '&template=/' +
                executionScope.getRequiredVariable('application').homeUrl
        );
    }
}
