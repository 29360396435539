import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';

declare var loginIdp: any;

@Injectable()
export class LoginIdp implements Method {
    execute(executionScope: ExecutionScope): void {
        loginIdp(executionScope.getRequiredVariable('name'));
    }
}
