import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxFieldPartFrontComponent } from './components/checkbox-field-part-front/checkbox-field-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [CheckboxFieldPartFrontComponent],
    imports: [
        CommonModule,
        NgxFloatUiModule,
        MatIconModule,
        MatCheckboxModule,
        MatGridListModule,
        NgxFloatUiModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class CheckboxFieldPartModule {
    constructor() {
        getPartDetailComponents().set('checkbox-field', CheckboxFieldPartFrontComponent);
    }
}
