import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { OnSameUrlNavigation, Router } from '@angular/router';
import { TemplateFacade } from '../../../../../data-access/template/src/lib/facade/template.facade';

@Injectable()
export class ScrollTo implements Method {
    constructor(
        private router: Router,
        private templateFacade: TemplateFacade
    ) {}
    execute(executionScope: ExecutionScope): void {
        const partId = executionScope.getRequiredVariable('part');
        const positionExact = executionScope.getOptionalVariable('position_exact', null);
        const position = executionScope.getRequiredVariable('position');
        if (partId != null) {
            const part = document.getElementById('id-' + partId);
            if (!!part) {
                if (position === 'EXACT' && !!positionExact) {
                    setTimeout(
                        () =>
                            part.scrollTo({
                                top: positionExact,
                                left: 0,
                                behavior: 'smooth',
                            }),
                        200
                    );
                } else if (position === 'BOTTOM') {
                    setTimeout(
                        () =>
                            part.scrollTo({
                                top: part.scrollHeight,
                                left: 0,
                                behavior: 'smooth',
                            }),
                        200
                    );
                } else if (position === 'TOP') {
                    setTimeout(
                        () =>
                            part.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            }),
                        200
                    );
                } else if (position === 'CENTER') {
                    setTimeout(
                        () =>
                            part.scrollTo({
                                top: part.scrollHeight / 2,
                                left: 0,
                                behavior: 'smooth',
                            }),
                        200
                    );
                }
            }
        } else {
            if (position === 'EXACT' && !!positionExact) {
                setTimeout(
                    () =>
                        window.scrollTo({
                            top: positionExact,
                            left: 0,
                            behavior: 'smooth',
                        }),
                    200
                );
            } else if (position === 'BOTTOM') {
                setTimeout(
                    () =>
                        window.scrollTo({
                            top: document.documentElement.scrollHeight - window.innerHeight,
                            left: 0,
                            behavior: 'smooth',
                        }),
                    200
                );
            } else if (position === 'TOP') {
                setTimeout(
                    () =>
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        }),
                    200
                );
            } else if (position === 'CENTER') {
                setTimeout(
                    () =>
                        window.scrollTo({
                            top: document.documentElement.scrollHeight / 2 - window.innerHeight,
                            left: 0,
                            behavior: 'smooth',
                        }),
                    200
                );
            }
        }
    }
}
