<h1
    *ngIf="partDetail.level === 1"
    [id]="'id-' + part.id"
    class="part-content"
    (click)="executeOnClickAction()"
    [matTooltip]="partDetail.processedTooltip">
    {{ partDetail.processedTitle }}
</h1>
<h2
    *ngIf="partDetail.level === 2"
    [id]="'id-' + part.id"
    class="part-content"
    (click)="executeOnClickAction()"
    [matTooltip]="partDetail.processedTooltip">
    {{ partDetail.processedTitle }}
</h2>
<h3
    *ngIf="partDetail.level === 3"
    [id]="'id-' + part.id"
    class="part-content"
    (click)="executeOnClickAction()"
    [matTooltip]="partDetail.processedTooltip">
    {{ partDetail.processedTitle }}
</h3>
<h4
    *ngIf="partDetail.level === 4"
    [id]="'id-' + part.id"
    class="part-content"
    (click)="executeOnClickAction()"
    [matTooltip]="partDetail.processedTooltip">
    {{ partDetail.processedTitle }}
</h4>
<h5
    *ngIf="partDetail.level === 5"
    [id]="'id-' + part.id"
    class="part-content"
    (click)="executeOnClickAction()"
    [matTooltip]="partDetail.processedTooltip">
    {{ partDetail.processedTitle }}
</h5>
<h6
    *ngIf="partDetail.level === 6"
    [id]="'id-' + part.id"
    class="part-content"
    (click)="executeOnClickAction()"
    [matTooltip]="partDetail.processedTooltip">
    {{ partDetail.processedTitle }}
</h6>
