import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateversionComponent } from './pages/templateversion/templateversion.component';
import { templateVersionRouting } from './templateversion.routing';
import { DeveloperControlsComponent } from './components/developer-controls/developer-controls.component';
import { HttpErrorInterceptorProvider } from '../http/http-error.interceptor';
import { HttpAuthenticationInterceptorProvider } from '../http/http-authentication.interceptor';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { SharedTemplateModule } from '../shared-template/shared-template.module';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { TruncatePipe } from '../lib/pipe/truncate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { HttpLoaderInterceptorProvider } from '../http/http-loader.interceptor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HttpXsrfInterceptorProvider } from '../http/http-csrf.interceptor';
import { FrontofficeFeatureInterpretionModule } from '@frontoffice/feature/interpretion';
import { FrontofficeDataAccessTemplateModule } from '@frontoffice/data-access/template';
import { StatusModule } from '../status/status.module';

@NgModule({
    declarations: [TemplateversionComponent, DeveloperControlsComponent, TruncatePipe],
    imports: [
        HttpClientModule,
        HttpClientXsrfModule,
        SharedTemplateModule,
        CommonModule,
        MatButtonModule,
        MatTooltipModule,
        MatListModule,
        MatIconModule,
        FrontofficeFeatureInterpretionModule,
        FrontofficeDataAccessTemplateModule,
        StatusModule,
        templateVersionRouting,
    ],
    providers: [
        HttpXsrfInterceptorProvider,
        HttpErrorInterceptorProvider,
        HttpAuthenticationInterceptorProvider,
        HttpLoaderInterceptorProvider,
        { provide: Window, useValue: window },
    ],
    exports: [TemplateversionComponent],
})
export class TemplateversionModule {}
