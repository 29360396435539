import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApplicationDto } from '../dto/application.dto.interface';

@Injectable({
    providedIn: 'root',
})
export class ApplicationService {
    constructor(private httpClient: HttpClient) {}

    getApplication(host: string): Observable<ApplicationDto> {
        return this.httpClient.get<ApplicationDto>(`${host}/v2/application`);
    }

    getApplicationById(host: string): Observable<ApplicationDto> {
        return this.httpClient.get<ApplicationDto>(`${host}/v2/application`);
    }
}
