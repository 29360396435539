import { Type } from 'class-transformer';
import { TemplateVersion } from './template-version.model';
import { ActionExecutionFrontEndActionDto } from '../dto/action-execution-frontend-action.dto';

export class TemplateVersionResponse {
    @Type(() => TemplateVersion)
    templateVersion: TemplateVersion;
    result?: string;
    onEntryActions?: string[];
    frontEndActions: ActionExecutionFrontEndActionDto[];
    resetParts: boolean = true;
}
