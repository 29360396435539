import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export class IconPartDetail extends PartDetail {
    code: string;
    icon: string;
    tooltip: string;
    processedToolTip: string;
    processedIcon: string;
}

export async function replaceArguments(detail: IconPartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    detail.processedToolTip = await replaceArgumentsForString(detail.tooltip, templateArguments);
    detail.processedIcon = await replaceArgumentsForString(detail.icon, templateArguments);
}
