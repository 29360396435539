import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizontallistPartFrontComponent } from './components/horizontallist-part-front/horizontallist-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { PartComponent } from './components/part/part.component';

@NgModule({
    declarations: [HorizontallistPartFrontComponent, PartComponent],
    imports: [CommonModule],
})
export class HorizontallistPartModule {
    constructor() {
        getPartDetailComponents().set('horizontal-list', HorizontallistPartFrontComponent);
    }
}
