import { applicationReducer } from './application/application.reducer';
import { partInitialState, partReducer } from './part/part.reducer';
import {
    externalAppReducer,
    templateBaseInitialState,
    templateBaseReducer,
    templateByHostInitialState,
    templateByHostReducer,
    templateByIdInitialState,
    templateByIdReducer,
} from './template/template.reducer';

export const appReducers = {
    application: applicationReducer(undefined),
    externalApp: externalAppReducer({}),
    parts: partReducer(partInitialState),
    templatesById: templateByIdReducer(templateByIdInitialState),
    templatesByHost: templateByHostReducer(templateByHostInitialState),
    templatesBase: templateBaseReducer(templateBaseInitialState),
};
