import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export class PagingPartDetail extends PartDetail {
    code: string;
    pages: number;
    currentPage: number;
    pageText: string;
    pageTextProcessed: string;
    ofText: string;
    ofTextProcessed: string;
}

export async function replaceArguments(detail: PagingPartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    detail.pageTextProcessed = await replaceArgumentsForString(detail.pageText, templateArguments);
    detail.ofTextProcessed = await replaceArgumentsForString(detail.ofText, templateArguments);
}
