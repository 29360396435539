<button mat-stroked-button *ngIf="!showLogs" color="primary" (click)="showLogs = true; addHeightToBody()">Logs</button>
<button mat-stroked-button *ngIf="showLogs" (click)="refreshLogs()" color="primary">Refresh logs</button>
<button mat-stroked-button *ngIf="showLogs" (click)="showLogs = false; removeHeightFromBody()" color="primary">Hide logs</button>
<ng-container *ngIf="showLogs">
    <mat-list>
        <mat-list-item *ngFor="let logLine of logs | async">
            <div class="log-line">
                <mat-icon *ngIf="expandedLogLines.indexOf(logLine.id) > -1" (click)="expandToggle(logLine)">expand_more </mat-icon>
                <mat-icon *ngIf="expandedLogLines.indexOf(logLine.id) === -1" (click)="expandToggle(logLine)"> expand_less </mat-icon>
                <mat-icon [ngClass]="logLine.level" [matTooltip]="logLine.level">{{ getIcon(logLine.level) }}</mat-icon>
                <mat-icon [ngClass]="logLine.actionId">bolt</mat-icon>
                <span class="log-line-date">{{ logLine.date | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
                <span class="log-line-message">{{ logLine.message | truncate: 100 }}</span>
            </div>
            <div *ngIf="expandedLogLines.indexOf(logLine.id) > -1" class="log-line-message-expanded">{{ logLine.message }}</div>
        </mat-list-item>
    </mat-list>
</ng-container>
