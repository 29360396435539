import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanePartFrontComponent } from './components/plane-part-front/plane-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TemplateFrontModule } from '../../template-front/template-front.module';

@NgModule({
    declarations: [PlanePartFrontComponent],
    imports: [CommonModule, MatTooltipModule, TemplateFrontModule],
})
export class PlanePartModule {
    constructor() {
        getPartDetailComponents().set('plane', PlanePartFrontComponent);
    }
}
