import { createAction as createNgRxAction, props } from '@ngrx/store';
import { Part } from '@frontoffice/data-access/template';

export const fetchPartSuccess = createNgRxAction('[EFFECT] fetch part success', props<{ parts: Part[] }>());
export const fetchPartError = createNgRxAction('[ACTION] fetch part error', props<{ part: Part }>());

export const removePartInstanceFromStore = createNgRxAction(
    '[ACTION] remove part instance from store',
    props<{ instanceIdentifier: string }>()
);

export const updatePart = createNgRxAction('[ACTION] update part', props<{ part: Part }>());
export const updatePartsSuccess = createNgRxAction('[EFFECT] update part success', props<{ parts: Part[] }>());
