import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterpretionService } from './service/interpretion.service';
import { TemplateService } from './service/template.service';
import { ActionService } from './service/action.service';
import { TemplateFacade } from './facade/template.facade';
import { HttpXsrfInterceptorProvider } from '../../../../../../apps/no-code-x-frontoffice/src/app/http/http-csrf.interceptor';
import { HttpErrorInterceptorProvider } from '../../../../../../apps/no-code-x-frontoffice/src/app/http/http-error.interceptor';
import { HttpAuthenticationInterceptorProvider } from '../../../../../../apps/no-code-x-frontoffice/src/app/http/http-authentication.interceptor';
import { HttpLoaderInterceptorProvider } from '../../../../../../apps/no-code-x-frontoffice/src/app/http/http-loader.interceptor';

@NgModule({
    imports: [CommonModule],
    providers: [
        HttpXsrfInterceptorProvider,
        HttpErrorInterceptorProvider,
        HttpAuthenticationInterceptorProvider,
        HttpLoaderInterceptorProvider,
        { provide: Window, useValue: window },
        InterpretionService,
        ActionService,
        TemplateService,
        TemplateFacade,
    ],
})
export class FrontofficeDataAccessTemplateModule {}
