import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export class LinkPartDetail extends PartDetail {
    code: string;
    url: string;
    processedUrl: string;
    text: string;
    processedText: string;
    target: string;
}

export async function replaceArguments(detail: LinkPartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    detail.processedUrl = await replaceArgumentsForString(detail.url, templateArguments);
    detail.processedText = await replaceArgumentsForString(detail.text, templateArguments);
}
