import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export class TitlePartDetail extends PartDetail {
    code: string;
    title: string;
    processedTitle: string;
    level: number;
    tooltip: string;
    processedTooltip: string;
}

export async function replaceArguments(detail: TitlePartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    detail.processedTitle = await replaceArgumentsForString(detail.title, templateArguments);
    detail.processedTooltip = await replaceArgumentsForString(detail.tooltip, templateArguments);
}
