import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizontalDividerFrontComponent } from './components/horizontal-divider-front/horizontal-divider-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
    declarations: [HorizontalDividerFrontComponent],
    imports: [CommonModule, MatDividerModule],
})
export class HorizontalDividerModule {
    constructor() {
        getPartDetailComponents().set('horizontal-divider', HorizontalDividerFrontComponent);
    }
}
