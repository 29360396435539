import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsPartFrontComponent } from './components/tabs-part-front/tabs-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { PartComponent } from './components/part/part.component';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
    declarations: [TabsPartFrontComponent, PartComponent],
    imports: [CommonModule, MatTabsModule, MatIconModule, MatStepperModule],
})
export class TabsPartModule {
    constructor() {
        getPartDetailComponents().set('tabs', TabsPartFrontComponent);
    }
}
