import { Part, PartDetail } from '@frontoffice/data-access/template';
import { Type } from 'class-transformer';

export class HorizontalListPartDetail extends PartDetail {
    override code: string;
    @Type(() => Part)
    override parts?: Part[];

    partSelectorIds: string[] = [];

    override getChildParts(): Part[] {
        if (this.parts) {
            return this.parts ? this.parts : [];
        } else {
            return [];
        }
    }
}

export function sortParts(parts: Part[], partSelectorIds: string[]) {
    if (parts && parts.length > 0) {
        parts.sort((a, b) => partSelectorIds.indexOf(a.selectorId) - partSelectorIds.indexOf(b.selectorId));
    }
}
