import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsInputFieldPartFrontComponent } from './components/chips-input-field-part-front/chips-input-field-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
    declarations: [ChipsInputFieldPartFrontComponent],
    imports: [CommonModule, NgxFloatUiModule, MatIconModule, MatInputModule, MatChipsModule, FormsModule, ReactiveFormsModule],
})
export class ChipsInputFieldPartModule {
    constructor() {
        getPartDetailComponents().set('chips-input-field', ChipsInputFieldPartFrontComponent);
    }
}
