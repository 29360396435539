import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export class RadiobuttonFieldPartDetail extends PartDetail {
    code: string;
    label: string;
    processedLabel: string;

    required: boolean;
    requiredMessage: string;
    processedRequiredMessage: string;

    help: boolean;
    helpInformation: string;
    processedHelpInformation: string;

    answer: string;
    processedAnswer: string;

    choices: RadiobuttonFieldPartChoice[];

    enabled: boolean;
}

export async function replaceArguments(detail: RadiobuttonFieldPartDetail, templateArguments: TemplateArgument[]) {
    detail.processedLabel = await replaceArgumentsForString(detail.label, templateArguments);
    detail.processedRequiredMessage = await replaceArgumentsForString(detail.requiredMessage, templateArguments);
    detail.processedHelpInformation = await replaceArgumentsForString(detail.helpInformation, templateArguments);
    detail.processedAnswer = await replaceArgumentsForString(detail.answer, templateArguments);
    if (detail.choices) {
        for (const choice of detail.choices) {
            choice.processedLabel = await replaceArgumentsForString(choice.label, templateArguments);
            choice.processedHelpInformation = await replaceArgumentsForString(choice.helpInformation, templateArguments);
        }
    }
}

export class RadiobuttonFieldPartChoice {
    id?: string;
    code?: string;
    label?: string;
    processedLabel: string;

    help?: boolean;
    helpInformation?: string;
    processedHelpInformation: string;
}
