import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export interface EmailInputFieldPartDetail extends PartDetail {
    code: string;
    label: string;
    processedLabel: string;

    required: boolean;
    requiredMessage: string;
    processedRequiredMessage: string;

    help: boolean;
    helpInformation: string;
    processedHelpInformation: string;

    invalidEmailMessage: string;
    processedInvalidEmailMessage: string;

    invalidMessage: string | null;

    answer: string;
    processedAnswer: string;

    enabled: boolean;
}

export async function replaceArguments(detail: EmailInputFieldPartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    detail.processedLabel = await replaceArgumentsForString(detail.label, templateArguments);
    detail.processedRequiredMessage = await replaceArgumentsForString(detail.requiredMessage, templateArguments);
    detail.processedHelpInformation = await replaceArgumentsForString(detail.helpInformation, templateArguments);
    detail.processedInvalidEmailMessage = await replaceArgumentsForString(detail.invalidEmailMessage, templateArguments);
    detail.processedAnswer = await replaceArgumentsForString(detail.answer, templateArguments);
}
