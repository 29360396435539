<div *ngIf="partDetail && formGroup" [formGroup]="formGroup" [id]="'id-' + part.id" class="part-content">
    <mat-form-field appearance="outline" dataFormat-name="form-code" subscriptSizing="dynamic">
        <mat-label>{{ partDetail.processedLabel }}</mat-label>
        <input
            matInput
            [type]="showPassword ? 'text' : 'password'"
            name="answer"
            align="end"
            [required]="partDetail.required"
            formControlName="value" />
        <mat-error *ngIf="valueControl().touched && valueControl().invalid && partDetail.required && valueControl().hasError('required')">{{
            partDetail.processedRequiredMessage
        }}</mat-error>
        <mat-error
            *ngIf="
                valueControl().touched &&
                valueControl().invalid &&
                partDetail.validatePasswordStrength &&
                valueControl().hasError('passwordStrength')
            "
            >{{ partDetail.processedValidatePasswordStrengthMessage }}</mat-error
        >
        <mat-error *ngIf="valueControl().hasError('invalidmessage')">{{ partDetail.invalidMessage }}</mat-error>
        <mat-icon
            style="cursor: pointer; padding-right: 8px; padding-left: 8px"
            color="primary"
            *ngIf="partDetail.showPasswordIcon"
            matSuffix
            (click)="toggleShowPassword()"
            >{{ this.showPassword ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        <mat-icon
            style="padding-right: 8px; padding-left: 8px"
            class="help-icon"
            color="primary"
            *ngIf="partDetail.help"
            matSuffix
            [showTrigger]="NgxFloatUiTriggers.hover"
            appendTo="body"
            [floatUi]="questionInformationPopover"
            >help
        </mat-icon>
        <float-ui-content #questionInformationPopover>
            <div class="popover">
                <div [innerHTML]="partDetail.processedHelpInformation"></div>
            </div>
        </float-ui-content>
    </mat-form-field>
</div>
