import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateComponent } from './components/template/template.component';
import { ProgressComponent } from './components/progress/progress.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedUiComponentsModule } from '@shared/ui/components';

@NgModule({
    declarations: [TemplateComponent, ProgressComponent],
    imports: [CommonModule, MatProgressSpinnerModule, SharedUiComponentsModule],
    exports: [TemplateComponent, ProgressComponent],
})
export class SharedTemplateModule {}
