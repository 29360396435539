import { ChangeDetectorRef, Component, EventEmitter, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UploaderPartDetail } from '../../model/uploader-part.detail';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { frontofficeEnvironment } from '@shared/environment';
import { DOCUMENT } from '@angular/common';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { UploaderPartStyle } from '../../model/uploader-part.style';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { KeycloakService } from 'keycloak-angular';

@Component({
    selector: 'app-uploader-part-front',
    templateUrl: './uploader-part-front.component.html',
    styleUrls: ['./uploader-part-front.component.scss'],
})
export class UploaderPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: UploaderPartDetail = null;
    partStyle: UploaderPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{ trigger: string; actionLinks: PartActionLink[]; arguments: TemplateArgument[] }>;

    formGroup: FormGroup;

    parentFormGroup: FormGroup;

    options: UploaderOptions;
    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
    humanizeBytes: Function;
    dragOver: boolean;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public keycloakService: KeycloakService,
        @Inject(DOCUMENT) private document: Document,
        private fb: FormBuilder
    ) {}

    ngOnChanges(changes: SimpleChanges): void {}

    ngOnInit(): void {
        this.options = {
            concurrency: this.partDetail.concurrency,
            maxUploads: this.partDetail.maxUploads,
            maxFileSize: this.partDetail.maxFileSize,
        };
        this.files = []; // local uploading files array
    }

    onUploadOutput(output: UploadOutput): void {
        console.log(output);
        switch (output.type) {
            case 'allAddedToQueue':
                if (!this.partDetail.showUploadButton) {
                    const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
                        return {
                            name: templateArgument.name,
                            value: templateArgument.value,
                            calculatedValue: templateArgument.calculatedValue,
                        };
                    });
                    this.keycloakService.getToken().then(token => {
                        const event: UploadInput = {
                            type: 'uploadAll',
                            url: this.getHost(this.document.location) + '/v2/media/upload',
                            method: 'POST',
                            headers: { Authorization: 'Bearer ' + token },
                            data: {
                                template: JSON.stringify(TemplateVersion.prepareForAction(this.templateVersion)),
                                partSelectorId: this.part.selectorId,
                                applicationId: this.application.applicationId,
                                companyId: this.application.companyId,
                                arguments: JSON.stringify(templateActionArguments),
                                fileName: this.files[0] ? this.files[0].name : null,
                                contentType: this.files[0] ? this.files[0].type : null,
                            },
                        };
                        this.uploadInput.emit(event);
                    });
                } else {
                }

                break;
            case 'addedToQueue':
                if (typeof output.file !== 'undefined') {
                    this.files.push(output.file);
                }
                break;
            case 'uploading':
                if (typeof output.file !== 'undefined') {
                    // update current data in files array for uploading file
                    const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
                    this.files[index] = output.file;
                }
                break;
            case 'removed':
                // remove file from array when removed
                this.files = this.files.filter((file: UploadFile) => file !== output.file);
                break;
            case 'rejected':
                console.log(output);
                // remove file from array when removed
                this.files = this.files.filter((file: UploadFile) => file !== output.file);
                break;
            case 'dragOver':
                this.dragOver = true;
                break;
            case 'dragOut':
            case 'drop':
                this.dragOver = false;
                break;
            case 'done':
                // The file is downloaded
                break;
        }
    }

    startUpload(): void {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
            };
        });

        this.files.forEach(file => {
            this.keycloakService.getToken().then(token => {
                const event: UploadInput = {
                    type: 'uploadFile',
                    url: this.getHost(this.document.location) + '/v2/media/upload',
                    method: 'POST',
                    headers: { Authorization: 'Bearer ' + token },
                    file: file,
                    data: {
                        template: JSON.stringify(TemplateVersion.prepareForAction(this.templateVersion)),
                        partSelectorId: this.part.selectorId,
                        applicationId: this.application.applicationId,
                        companyId: this.application.companyId,
                        arguments: JSON.stringify(templateActionArguments),
                        fileName: file.name,
                        contentType: file.type,
                    },
                };

                this.uploadInput.emit(event);
            });
        });
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    removeFile(id: string): void {
        this.uploadInput.emit({ type: 'remove', id: id });
    }

    removeAllFiles(): void {
        this.uploadInput.emit({ type: 'removeAll' });
    }

    private getHost(location: Location) {
        const host = location.hostname;
        if (host !== 'localhost') {
            if (host.indexOf('app.nocode-x.com') > -1) {
                return 'https://' + host.replace('app.nocode-x.com', 'back.nocode-x.com');
            } else {
                if (host.startsWith('dev-')) {
                    return 'https://' + 'dev-back-' + host.replace('dev-', '');
                } else if (host.startsWith('test-')) {
                    return 'https://' + 'test-back-' + +host.replace('test-', '');
                } else if (host.startsWith('accept-')) {
                    return 'https://' + 'accept-back-' + host.replace('accept-', '');
                } else {
                    return 'https://' + 'back-' + host;
                }
            }
        }
        return frontofficeEnvironment.localTestApp ? frontofficeEnvironment.localTestApp : null;
    }
}
