import 'reflect-metadata';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'codex-progress',
    styleUrls: ['./progress.component.scss'],
    template: ` <div class="progress">
        <mat-progress-spinner strokeWidth="2" diameter="50" mode="indeterminate"></mat-progress-spinner>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressComponent {}
