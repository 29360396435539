import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarChartPartFrontComponent } from './components/bar-chart-part-front/bar-chart-part-front.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { getPartDetailComponents } from '../../../part-module';

@NgModule({
    declarations: [BarChartPartFrontComponent],
    imports: [CommonModule, NgxEchartsModule],
})
export class BarChartPartModule {
    constructor() {
        getPartDetailComponents().set('bar-chart', BarChartPartFrontComponent);
    }
}
