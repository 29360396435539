import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFieldPartFrontComponent } from './components/date-field-part-front/date-field-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
    declarations: [DateFieldPartFrontComponent],
    imports: [
        CommonModule,
        FormsModule,
        NgxFloatUiModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
    ],
})
export class DateFieldPartModule {
    constructor() {
        getPartDetailComponents().set('date-field', DateFieldPartFrontComponent);
    }
}
