import 'reflect-metadata';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { frontofficeEnvironment } from '@shared/environment';

//declare let __webpack_public_path__: any;
//__webpack_public_path__ = "http://localhost:4201/";

if (frontofficeEnvironment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
