import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getPartDetailComponents } from '../../../part-module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { PartComponent } from './components/part/part.component';
import { MatStepperModule } from '@angular/material/stepper';
import { AccordionPartFrontComponent } from './components/accordion-part-front/accordion-part-front.component';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    declarations: [AccordionPartFrontComponent, PartComponent],
    imports: [CommonModule, MatTabsModule, MatIconModule, MatStepperModule, MatExpansionModule],
})
export class AccordionPartModule {
    constructor() {
        getPartDetailComponents().set('accordion', AccordionPartFrontComponent);
    }
}
