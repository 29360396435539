import { ChangeDetectorRef, Component, EventEmitter, HostBinding, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { sortParts, VerticalListPartDetail } from '../../model/vertical-list-part.detail';
import { VerticalListPartStyle } from '../../model/vertical-list-part.style';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    selector: 'app-verticallist-part-front',
    templateUrl: './verticallist-part-front.component.html',
    styleUrls: ['./verticallist-part-front.component.scss'],
})
export class VerticallistPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: VerticalListPartDetail = null;
    partStyle: VerticalListPartStyle = null;

    part: Part = null;

    host = '';

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    partSort$: BehaviorSubject<string[]> = new BehaviorSubject(null);

    public subscriptions: Subscription = new Subscription();

    @HostBinding('style.width') width: string;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public window: Window
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.partSort$.subscribe(partSelectorIds => {
                if (partSelectorIds) {
                    sortParts(this.partDetail.parts, partSelectorIds);
                }
            })
        );

        this.partSort$.next(this.partDetail.partSelectorIds);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.part.detail.code === 'VERTICAL_LIST_CODE2') {
            console.log(this.partDetail.parts);
        }
        this.partSort$.next(this.partDetail.partSelectorIds);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    identifyPart(index, item) {
        // Adding templateID to identify this part is a very hacky way to make sure template parts should be refreshed if
        // They have the same selectorId but a different template configured.
        // This can happen when a user copies a page & then changes the template in the copied page.
        // Without templateID in this return value, hopping from the copied page to the original page & back will not result in changing the content of
        // the template component.
        return item.selectorId + item.detail['templateId'];
    }

    onClick() {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CLICK');
        if (actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks: actionLinks,
                arguments: templateActionArguments,
            });
        }
    }
}
