import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconButtonPartFrontComponent } from './component/icon-button-part-front/icon-button-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { A11yModule } from '@angular/cdk/a11y';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [IconButtonPartFrontComponent],
    imports: [
        CommonModule,
        NgxFloatUiModule,
        MatIconModule,
        MatButtonModule,
        MatRippleModule,
        MatTooltipModule,
        A11yModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class IconButtonPartModule {
    constructor() {
        getPartDetailComponents().set('icon-button', IconButtonPartFrontComponent);
    }
}
