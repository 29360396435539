import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatablePartFrontComponent } from './components/datatable-part-front/datatable-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { TemplateFrontModule } from '../../template-front/template-front.module';

@NgModule({
    declarations: [DatatablePartFrontComponent],
    imports: [CommonModule, MatTableModule, MatFormFieldModule, MatInputModule, MatPaginatorModule, MatSortModule, TemplateFrontModule],
})
export class DatatablePartModule {
    constructor() {
        getPartDetailComponents().set('datatable', DatatablePartFrontComponent);
    }
}
