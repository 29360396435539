import { FrontofficeEnvironmentDto } from './frontoffice-environment.dto';

export const frontofficeEnvironment: FrontofficeEnvironmentDto = {
    inputdebounce: 300,
    production: true,
    onEntryOnBackend: false,
    amLink: 'https://login.nocode-x.com/auth',
    redirectUrl: 'https://nocode-x.back.nocode-x.com/redirect',
    localTestApp: undefined,
    //localTestApp: 'https://regional-invest-regional-invest.back.nocode-x.com/',
};
