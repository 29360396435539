import { ApplicationDto } from '../../dto/application.dto.interface';
import { createReducer, on } from '@ngrx/store';
import {
    loadCssSuccess,
    requestApplicationInformationError,
    requestApplicationInformationSuccess,
    requestInitAuthenticationSuccess,
} from './application.actions';

export const applicationReducer = (initialData: ApplicationDto) =>
    createReducer(
        initialData,
        on(requestInitAuthenticationSuccess, (_, { application }) => application),
        on(loadCssSuccess, (_, { application }) => {
            application.cssLoaded = true;
            return Object.assign({}, application);
        }),
        on(requestApplicationInformationError, () => undefined)
    );
