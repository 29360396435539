import { Type } from 'class-transformer';
import { PartActionLink } from '../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from './template-argument.model';
import { Part } from './part.model';
import { TemplateStyleDto } from '../dto/template.style.dto';
import { TemplateDto } from '../dto/template.dto.interface';
import { TemplatePartDetail } from '../components/layout/template/template-part.detail';

export class TemplateVersion {
    id?: string;
    instanceIdentifier?: string;
    baseIdentifier?: string;
    @Type(() => Part)
    parts?: Part[] = [];

    style?: TemplateStyleDto;
    sizeX?: number;
    sizeXUnit?: string;
    sizeY?: number;
    sizeYUnit?: string;

    path?: string;

    partsByContainer?: Map<string, Part[]>;

    templateDto: TemplateDto;

    arguments: TemplateArgument[] = [];

    metaTitle: string;
    metaDescription: string;

    @Type(() => PartActionLink)
    actionLinks: PartActionLink[] = [];

    getPartsOfContainer(containerId: string) {
        this.initializePartsByContainer();
        return this.partsByContainer.get(containerId);
    }

    initializePartsByContainer() {
        if (!this.partsByContainer) {
            this.partsByContainer = new Map();
            this.parts.forEach(part => {
                if (!this.partsByContainer.has(part.containerId + '-' + part.secondaryContainerId)) {
                    this.partsByContainer.set(part.containerId + '-' + part.secondaryContainerId, []);
                }
                this.partsByContainer.get(part.containerId + '-' + part.secondaryContainerId).push(part);
            });
        }
    }

    public static getWidth(templateVersion: TemplateVersion) {
        if (templateVersion.sizeXUnit === 'pixels') {
            return templateVersion.sizeX + 'px';
        } else if (templateVersion.sizeXUnit === 'percentage') {
            return templateVersion.sizeX + '%';
        } else if (templateVersion.sizeXUnit === 'viewport') {
            return templateVersion.sizeX + 'vh';
        } else {
            return templateVersion.sizeY + 'px';
        }
    }

    public static getHeight(templateVersion: TemplateVersion) {
        if (templateVersion.sizeYUnit === 'pixels') {
            return templateVersion.sizeY + 'px';
        } else if (templateVersion.sizeYUnit === 'percentage') {
            return templateVersion.sizeY + '%';
        } else if (templateVersion.sizeYUnit === 'viewport') {
            return templateVersion.sizeY + 'vh';
        } else {
            return templateVersion.sizeY + 'px';
        }
    }

    public static setValueOnPartDetail(parts: Part[] | undefined, partId: string, attributeName: string, attributeValue: any) {
        if (parts && parts.length > 0) {
            const part: Part = TemplateVersion.findPart(parts, partId);
            if (part) {
                part.detail[attributeName] = attributeValue;
            }
        }
    }

    public static createPartsByInstanceIdentifier(parts: Part[] | undefined): Map<string, Part> {
        const partById: Map<string, Part> = new Map();
        if (parts && parts.length > 0) {
            for (const part of parts) {
                if (part.instanceIdentifier) {
                    partById.set(part.instanceIdentifier, part);
                    if (part.detail.parts && part.detail.parts.length > 0) {
                        const subPartsById = TemplateVersion.createPartsByInstanceIdentifier(part.detail.parts);
                        subPartsById.forEach((value, key, map) => partById.set(key, value));
                    }
                    if ('templateVersion' in part.detail && !!(part.detail as TemplatePartDetail).templateVersion) {
                        const subPartsById = TemplateVersion.createPartsByInstanceIdentifier(
                            (part.detail as TemplatePartDetail).templateVersion.parts
                        );
                        subPartsById.forEach((value, key, map) => partById.set(key, value));
                    }
                }
            }
        }
        return partById;
    }

    public static findPart(parts: Part[] | undefined, partId: string): Part | null {
        if (parts && parts.length > 0) {
            for (const part of parts) {
                if (part.id === partId || part.selectorId === partId) {
                    return part;
                }
                if (part.detail.parts && part.detail.parts.length > 0) {
                    const foundPart = TemplateVersion.findPart(part.detail.parts, partId);
                    if (foundPart) {
                        return foundPart;
                    }
                }
            }
        }
        return null;
    }

    public static prepareForAction(templateVersion: TemplateVersion) {
        return {
            id: templateVersion.id,
            style: templateVersion.style,
            sizeX: templateVersion.sizeX,
            sizeY: templateVersion.sizeY,
            sizeXUnit: templateVersion.sizeXUnit,
            sizeYUnit: templateVersion.sizeYUnit,
            parts: templateVersion.parts?.map(part => Part.prepareForAction(part)),
            templateDto: templateVersion.templateDto,
            arguments: templateVersion.arguments,
        };
    }
}
