import { TemplateVersion } from '@frontoffice/data-access/template';
import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';

@Injectable()
export class OpenStep implements Method {
    execute(executionScope: ExecutionScope): void {
        const stepperId = executionScope.getRequiredVariable('stepper');
        const index = executionScope.getRequiredVariable('index');
        const templateVersion: TemplateVersion = executionScope.getRequiredVariable('template');
        const stepperPart = TemplateVersion.findPart(templateVersion.parts, stepperId);
        if (stepperPart && stepperPart.detail['steps'] && index <= stepperPart.detail['steps'].length) {
            TemplateVersion.setValueOnPartDetail(templateVersion.parts, stepperId, 'openedStep', index);
        }
    }
}
