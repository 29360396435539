import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, PartDetail, PartStyle, TemplateVersion } from '@frontoffice/data-access/template';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'app-treemap-part-front',
    templateUrl: './treemap-part-front.component.html',
})
export class TreemapPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    part: Part;
    partDetail: PartDetail;
    partStyle: PartStyle;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{ trigger: string; actionLinks: PartActionLink[]; arguments: TemplateArgument[] }>;

    parentFormGroup: FormGroup;

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {}
}
