import { ChangeDetectorRef, Component, EventEmitter, HostBinding, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { HorizontalListPartDetail, sortParts } from '../../model/horizontal-list-part.detail';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { FormGroup } from '@angular/forms';
import { HorizontalListPartStyle } from '../../model/horizontal-list-part.style';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'app-horizontallist-part-front',
    templateUrl: './horizontallist-part-front.component.html',
    styleUrls: ['./horizontallist-part-front.component.scss'],
})
export class HorizontallistPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: HorizontalListPartDetail = null;
    partStyle: HorizontalListPartStyle = null;

    part: Part = null;

    host = '';

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    @HostBinding('style.width') width: string;
    @HostBinding('style.position') position: string;
    @HostBinding('style.z-index') zIndex: string;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public window: Window
    ) {}

    ngOnInit(): void {
        sortParts(this.partDetail.parts, this.partDetail.partSelectorIds);
    }

    ngOnChanges(changes: SimpleChanges): void {
        sortParts(this.partDetail.parts, this.partDetail.partSelectorIds);
    }

    identifyPart(index, item) {
        // Adding templateID to identify this part is a very hacky way to make sure template parts should be refreshed if
        // They have the same selectorId but a different template configured.
        // This can happen when a user copies a page & then changes the template in the copied page.
        // Without templateID in this return value, hopping from the copied page to the original page & back will not result in changing the content of
        // the template component.
        return item.selectorId + item.detail['templateId'];
    }

    onClick() {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CLICK');
        if (actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks: actionLinks,
                arguments: templateActionArguments,
            });
        }
    }
}
