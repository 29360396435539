import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlInputFieldPartFrontComponent } from './components/url-input-field-part-front/url-input-field-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [UrlInputFieldPartFrontComponent],
    imports: [CommonModule, NgxFloatUiModule, FormsModule, ReactiveFormsModule, MatIconModule, MatInputModule],
})
export class UrlInputFieldPartModule {
    constructor() {
        getPartDetailComponents().set('url-input-field', UrlInputFieldPartFrontComponent);
    }
}
