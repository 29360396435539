import { Injectable } from '@angular/core';
import { InvocationDto } from '../dto/interpretion/invocation.dto';
import { MethodProvider } from '../../../../../feature/interpretion/src/lib/service/method.provider';
import { Method } from '../../../../../feature/interpretion/src/lib/methods/method';
import { ExecutionScope } from '../dto/interpretion/execution-scope';

@Injectable()
export class InterpretionService {
    constructor(private methodProvider: MethodProvider) {}
    public interprete(invocations: InvocationDto[], executionScope: ExecutionScope) {
        if (invocations && invocations.length > 0) {
            this.interpreteInvocation(invocations[0], this.createInvocationMap(invocations), executionScope);
        }
    }

    private interpreteInvocation(invocation: InvocationDto, invocationMap: Map<string, InvocationDto>, executionScope: ExecutionScope) {
        this.executeMethod(invocation, executionScope);
        if (invocation.nextInvocations && invocation.nextInvocations.length > 0) {
            invocation.nextInvocations.forEach(nextInvocation => {
                const nextInvocationInvocation = invocationMap.get(nextInvocation.invocationId);
                if (nextInvocationInvocation) {
                    this.interpreteInvocation(nextInvocationInvocation, invocationMap, executionScope);
                }
            });
        }
    }

    private executeMethod(invocation: InvocationDto, executionScope: ExecutionScope) {
        const method: Method | undefined = this.methodProvider.getMethod(invocation.methodKey);
        if (method) {
            executionScope.createLexicalScope(invocation);
            method.execute(executionScope);
            executionScope.destroyLexicalScope(invocation);
        }
    }

    private createInvocationMap(invocations: InvocationDto[]): Map<string, InvocationDto> {
        return new Map(invocations.map(invocation => [invocation.id, invocation]));
    }
}
