import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { PagingPartDetail, replaceArguments } from '../../model/paging-part.detail';
import { PagingPartStyle } from '../../model/paging-part.style';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'app-paging-part-front',
    templateUrl: './paging-part-front.component.html',
})
export class PagingPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: PagingPartDetail = null;
    partStyle: PagingPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{ trigger: string; actionLinks: PartActionLink[]; arguments: TemplateArgument[] }>;

    parentFormGroup: FormGroup;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public sanitizer: DomSanitizer,
        private router: Router,
        private readonly route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    ngOnChanges(changes: SimpleChanges) {
        replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    onNextPage() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { page: this.partDetail.currentPage - 1 + 1 },
            queryParamsHandling: 'merge',
        });
    }

    onPreviousPage() {
        if (this.partDetail.currentPage - 1 > 0) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { page: this.partDetail.currentPage - 1 - 1 },
                queryParamsHandling: 'merge',
            });
        }
    }

    isFirstPage() {
        return this.partDetail.currentPage === 1;
    }

    isLastPage() {
        return this.partDetail.currentPage === this.partDetail.pages;
    }
}
