import { createEntityAdapter } from '@ngrx/entity';
import { TemplateVersionResponse } from '@frontoffice/data-access/template';
import { createReducer, on } from '@ngrx/store';
import { TemplateBaseState, TemplateByHostState, TemplateByIdState } from './template.state';
import {
    fetchBaseTemplateByHostSuccess,
    fetchBaseTemplateByIdSuccess,
    fetchTemplateByHostSuccess,
    fetchTemplateByIdSuccess,
    removeTemplateInstanceFromStore,
    requestChangeExternalAppTemplate,
    updateTemplateById,
    updateTemplateByPath,
} from './template.action';
import { requestApplicationInformationError } from '../application/application.actions';
import { ExternalAppState } from './external-app.state';

const adapterTemplateByHost = createEntityAdapter<TemplateVersionResponse>({
    selectId: (entity: TemplateVersionResponse) => {
        return entity.templateVersion.instanceIdentifier;
    },
});
export const templateByHostInitialState: TemplateByHostState = adapterTemplateByHost.getInitialState();
export const templateByHostReducer = (initialData: TemplateByHostState) =>
    createReducer(
        initialData,
        on(fetchTemplateByHostSuccess, (state, { templateVersionResponse }) => {
            return adapterTemplateByHost.upsertOne(templateVersionResponse, state);
        }),
        on(updateTemplateByPath, (state, { templateVersion }) =>
            adapterTemplateByHost.updateOne(
                {
                    id: templateVersion.instanceIdentifier,
                    changes: { templateVersion: templateVersion },
                },
                state
            )
        )
    );

const adapterTemplateBase = createEntityAdapter<TemplateVersionResponse>({
    selectId: (entity: TemplateVersionResponse) => {
        return entity.templateVersion.baseIdentifier;
    },
});
export const templateBaseInitialState: TemplateBaseState = adapterTemplateBase.getInitialState();
export const templateBaseReducer = (initialData: TemplateBaseState) =>
    createReducer(
        initialData,
        on(fetchBaseTemplateByIdSuccess, (state, { templateVersionResponse }) => {
            const immutableTemplateVersion = JSON.parse(JSON.stringify(templateVersionResponse));
            return adapterTemplateBase.upsertOne(immutableTemplateVersion, state);
        }),
        on(fetchBaseTemplateByHostSuccess, (state, { templateVersionResponse }) => {
            const immutableTemplateVersion = JSON.parse(JSON.stringify(templateVersionResponse));
            return adapterTemplateBase.upsertOne(immutableTemplateVersion, state);
        })
    );

const adapterTemplateById = createEntityAdapter<TemplateVersionResponse>({
    selectId: (entity: TemplateVersionResponse) => {
        return entity.templateVersion.instanceIdentifier;
    },
});
export const templateByIdInitialState: TemplateByIdState = adapterTemplateById.getInitialState();
export const templateByIdReducer = (initialData: TemplateByIdState) =>
    createReducer(
        initialData,
        on(fetchTemplateByIdSuccess, (state, { templateVersionResponse }) => {
            return adapterTemplateById.upsertOne(templateVersionResponse, state);
        }),
        on(updateTemplateById, (state, { templateVersion }) => {
            return adapterTemplateById.updateOne(
                {
                    id: templateVersion.instanceIdentifier,
                    changes: { templateVersion: templateVersion },
                },
                state
            );
        }),
        on(removeTemplateInstanceFromStore, (state, { instanceIdentifier }) => {
            return adapterTemplateById.removeOne(instanceIdentifier, state);
        })
    );

export const externalAppReducer = (initialData: ExternalAppState) =>
    createReducer(
        initialData,
        on(requestChangeExternalAppTemplate, (_, { templateId, templateArguments, host, instanceIdentifier, path }) => {
            return {
                templateId,
                templateArguments,
                instanceIdentifier,
                host,
                path,
            };
        }),
        on(requestApplicationInformationError, () => undefined)
    );
