import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { UsageExceededComponent } from './pages/usage-exceeded/usage-exceeded.component';
import { statusRouting } from './status.routing';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { ErrorComponent } from './pages/error/error.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [NotFoundComponent, UsageExceededComponent, ForbiddenComponent, ErrorComponent],
    exports: [NotFoundComponent, UsageExceededComponent, ForbiddenComponent, ErrorComponent],
    imports: [CommonModule, MatIconModule, MatButtonModule, statusRouting],
})
export class StatusModule {}
