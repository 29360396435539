import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderFieldPartFrontComponent } from './components/slider-field-part-front/slider-field-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [SliderFieldPartFrontComponent],
    imports: [CommonModule, MatSliderModule, FormsModule, ReactiveFormsModule, NgxFloatUiModule, MatIconModule],
})
export class SliderFieldPartModule {
    constructor() {
        getPartDetailComponents().set('slider-field', SliderFieldPartFrontComponent);
    }
}
