import { createSelector } from '@ngrx/store';
import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { AppState } from '../app.state';
import { TemplateVersionResponse } from '@frontoffice/data-access/template';
import { ExternalAppState } from './external-app.state';

const selectState = (state: AppState) => state;

const templateByHostAdapter = createEntityAdapter<TemplateVersionResponse>();
export const templateByHostSelectors = {
    ids: createSelector(selectState, () => templateByHostAdapter.getSelectors().selectIds),
    all: createSelector(selectState, () => templateByHostAdapter.getSelectors().selectAll),
    total: createSelector(selectState, () => templateByHostAdapter.getSelectors().selectTotal),
    entities: createSelector(selectState, state => templateByHostAdapter.getSelectors().selectEntities(state.templatesByHost)),
    byId: (host: string) =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<TemplateVersionResponse> = templateByHostAdapter
                .getSelectors()
                .selectEntities(state.templatesByHost);
            return dictionary[host];
        }),
};

const templateBaseAdapter = createEntityAdapter<TemplateVersionResponse>();
export const templateBaseSelectors = {
    ids: createSelector(selectState, () => templateBaseAdapter.getSelectors().selectIds),
    all: createSelector(selectState, () => templateBaseAdapter.getSelectors().selectAll),
    total: createSelector(selectState, () => templateBaseAdapter.getSelectors().selectTotal),
    entities: createSelector(selectState, state => templateBaseAdapter.getSelectors().selectEntities(state.templatesBase)),
    byId: (host: string) =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<TemplateVersionResponse> = templateBaseAdapter.getSelectors().selectEntities(state.templatesBase);
            return dictionary[host];
        }),
};

const templateByIdAdapter = createEntityAdapter<TemplateVersionResponse>();

export const templateByIdSelectors = {
    ids: createSelector(selectState, () => templateByIdAdapter.getSelectors().selectIds),
    all: createSelector(selectState, () => templateByIdAdapter.getSelectors().selectAll),
    total: createSelector(selectState, () => templateByIdAdapter.getSelectors().selectTotal),
    entities: createSelector(selectState, state => templateByIdAdapter.getSelectors().selectEntities(state.templatesById)),
    byId: (host: string) =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<TemplateVersionResponse> = templateByIdAdapter.getSelectors().selectEntities(state.templatesById);
            return dictionary[host];
        }),
};

export const selectExternalApp = (state: AppState) => state.externalApp;
