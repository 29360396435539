import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagingPartFrontComponent } from './components/paging-part-front/paging-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [PagingPartFrontComponent],
    imports: [CommonModule, MatIconModule, MatButtonModule],
})
export class PagingPartModule {
    constructor() {
        getPartDetailComponents().set('paging', PagingPartFrontComponent);
    }
}
