import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { filter, Observable, Subscription } from 'rxjs';
import {
    ActionExecutionCreatedDto,
    ActionExecutionFrontEndActionDto,
    ActionService,
    TemplateVersion,
} from '@frontoffice/data-access/template';
import { ApplicationDto } from '../dto/application.dto.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { switchMap, take } from 'rxjs/operators';
import { PartActionLink } from '../shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../libs/frontoffice/data-access/template/src/lib/models/template-argument.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { requestApplicationInformation } from '../store/application/application.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { frontofficeEnvironment } from '@shared/environment';
import { KeycloakService } from 'keycloak-angular';
import { TemplateFacade } from '../../../../../libs/frontoffice/data-access/template/src/lib/facade/template.facade';
import { selectApplication } from '../store/application/application.selectors';

declare var login: any;
declare var routeToResetPasswordPage: any;
declare var resendVerifyEmailMail: any;
declare var resetPassword: any;
declare var updatePassword: any;
declare var loginIdp: any;
declare var configureOtp: any;
declare var checkOtp: any;

@Component({
    selector: 'app-external-app',
    templateUrl: './external-app.component.html',
})
export class ExternalAppComponent implements OnInit, OnChanges, OnDestroy {
    @Input('applicationid')
    public applicationId: string;

    @Input('templateid')
    public templateId: string;

    @Input()
    public host: string;

    @Input('loadparams')
    public loadParams = true;

    @Input()
    public params: TemplateArgument[];

    subscriptions: Subscription = new Subscription();

    template$: Observable<TemplateVersion> = null;

    application$: Observable<ApplicationDto> = this.store.select(selectApplication);

    parentFormGroup: FormGroup;

    dialogRefs: MatDialogRef<any>[] = [];

    constructor(
        private templateFacade: TemplateFacade,
        private actionService: ActionService,
        public fb: FormBuilder,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        private store: Store<AppState>,
        private keycloak: KeycloakService
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['templateId']?.currentValue !== changes['templateId']?.previousValue ||
            changes['applicationId']?.currentValue !== changes['applicationId']?.previousValue ||
            changes['host']?.currentValue !== changes['host']?.previousValue ||
            !this.equalParams(changes['params']?.currentValue, changes['params']?.previousValue)
        ) {
            this.initApplication();
        }
    }

    equalParams(paramsA: TemplateArgument[], paramsB: TemplateArgument[]): boolean {
        if ((!!paramsA && !paramsB) || (!!paramsB && !paramsA)) {
            return false;
        } else if (!!paramsA && !!paramsB && paramsA.length === paramsB.length) {
            for (let i = 0; i < paramsA.length; i++) {
                const paramA = paramsA[i];
                const paramB = paramsB[i];
                if (paramA.name !== paramB.name || paramA.calculatedValue !== paramB.calculatedValue) {
                    return false;
                }
            }
        } else {
            return false;
        }
        return true;
    }

    ngOnInit(): void {
        this.initApplication();
    }

    initApplication(): void {
        if (this.templateId && this.applicationId && this.host) {
            this.store.dispatch(requestApplicationInformation({ host: this.host, redirectToHome: false }));
            this.application$
                .pipe(
                    filter(application => !!application),
                    take(1)
                )
                .subscribe(application => {
                    if (application.customDomain) {
                        this.loadCssIfNotLoaded(`https://${application.customDomain}/styles.css`);
                        this.loadCssIfNotLoaded(`${this.host}/v2/application/style.css`);
                    } else {
                        this.loadCssIfNotLoaded(`${this.host.replace('back.', 'app.')}/styles.css`);
                        this.loadCssIfNotLoaded(`${this.host}/v2/application/style.css`);
                    }
                });
            this.loadCssIfNotLoaded(`https://fonts.googleapis.com/icon?family=Material+Icons`);
            this.loadJsIfNotLoaded('https://code.jquery.com/jquery-3.6.0.min.js');
            this.initializePage();
            this.parentFormGroup = this.fb.group({});
            this.parentFormGroup.addControl('parent', this.fb.group({}));
        }
    }

    loadJsIfNotLoaded(url): void {
        const element = document.querySelectorAll('script[src="' + url + '"]');
        if (element.length === 0) {
            const link = document.createElement('script');
            link.src = url;
            document.getElementsByTagName('head')[0].appendChild(link);
        }
    }

    loadCssIfNotLoaded(url): void {
        const element = document.querySelectorAll('link[href="' + url + '"]');
        if (element.length === 0) {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = url;
            document.getElementsByTagName('head')[0].appendChild(link);
        }
    }

    initializePage(): void {
        this.template$ = this.application$.pipe(
            filter(application => !!application),
            take(1),
            switchMap(() => {
                if (this.params) {
                    this.params.forEach(param => (param.calculatedValue = param.value));
                }
                return this.templateFacade.fetchExternalAppTemplate(this.templateId, this.params, this.host);
            })
        );
    }

    onExecuteAction($event: {
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
        templateInstanceIdentifier?: string;
        executionResultPartId?: string;
    }): void {
        const path = location.pathname;
        $event.actionLinks.forEach(actionLink => {
            return this.templateFacade.executeAction(
                $event.trigger,
                actionLink.type,
                [actionLink.actionId],
                $event.templateInstanceIdentifier ? $event.templateInstanceIdentifier : 'external-' + this.templateId,
                $event.arguments,
                $event.executionResultPartId,
                false,
                this.parentFormGroup,
                'external-' + this.templateId,
                this.host,
                true
            );
        });
    }

    routeToLoginPage() {
        this.application$.pipe(take(1)).subscribe(application => {
            const redirectUrl = this.getRedirectUrl(application);
            if (application.loginTemplateId) {
                const loginUrl = this.keycloak.getKeycloakInstance().createLoginUrl({
                    redirectUri: redirectUrl,
                    idpHint: application.forceIdp,
                });
                window.location.href = loginUrl;
            } else {
                this.keycloak.login({
                    redirectUri: redirectUrl,
                    idpHint: application.forceIdp,
                });
            }
        });
    }

    getRedirectUrl(application: ApplicationDto) {
        if (application.loginRedirectPath && application.loginRedirectHost) {
            if (location.host.startsWith('localhost')) {
                return (
                    frontofficeEnvironment.redirectUrl +
                    `?host=${location.protocol}//${location.host}&template=${application.loginRedirectPath}&params=${btoa(location.search)}`
                );
            } else {
                const hostNameParts = location.hostname.split('.');
                const subdomain = hostNameParts.shift();
                return (
                    frontofficeEnvironment.redirectUrl +
                    `?host=${location.protocol}//${application.loginRedirectHost + '.' + hostNameParts.join('.')}&template=${
                        application.loginRedirectPath
                    }&params=${btoa(location.search)}`
                );
            }
        } else {
            return (
                frontofficeEnvironment.redirectUrl +
                `?host=${location.protocol}//${location.host}&template=${location.pathname}&params=${btoa(location.search)}`
            );
        }
    }

    resetPassword(resetPasswordAction: ActionExecutionFrontEndActionDto): void {
        if (resetPasswordAction) {
            resetPassword(resetPasswordAction.arguments['email']);
        }
    }

    hideDialog(hideDialogAction: ActionExecutionFrontEndActionDto): void {
        if (hideDialogAction) {
            if (this.dialogRefs && this.dialogRefs.length > 0) {
                const dialogRef = this.dialogRefs.pop();
                dialogRef.close();
            }
        }
    }

    showDialog(showDialogAction: ActionExecutionFrontEndActionDto, $event?: { executionResultPartId?: string }): void {
        /*if (showDialogAction) {
            const dialogRef = this.dialog.open(TemplateversionDialogComponent, {
                width: showDialogAction.arguments['width'],
                height: showDialogAction.arguments['height'],
                data: {
                    templateId: showDialogAction.arguments['templateId'],
                    templateArguments: showDialogAction.arguments['templateParams'],
                },
                disableClose: showDialogAction.arguments['allowcloseclickoutside'],
            });
            this.dialogRefs.push(dialogRef);
            if (!!showDialogAction.arguments['aftercloseaction']) {
                this.subscriptions.add(
                    dialogRef
                        .afterClosed()
                        .pipe(
                            switchMap(result => {
                                return this.actionService.createActionExecution(
                                    this.host,
                                    this.application$.value.companyId,
                                    Object.assign(
                                        {
                                            currentUrl: {
                                                path: window.location.pathname,
                                                domain: window.location.host,
                                            },
                                        },
                                        showDialogAction.arguments['aftercloseaction'],
                                    ),
                                );
                            }),
                        )
                        .subscribe(result => {
                            this.handleActionExecutionResult(result, $event);
                        }),
                );
            }
        }*/
    }

    handleActionExecutionResult(result: ActionExecutionCreatedDto, $event?: { executionResultPartId?: string }) {
        /*this.checkFrontEndActions(result.frontEndActions, result.templateVersion, $event);
        const routeAction = result.frontEndActions.find(frontEndAction => frontEndAction.type === 'route');
        if (!routeAction && this.shouldReloadTemplate(result.frontEndActions)) {
            if ($event && $event.executionResultPartId) {
                const part = TemplateVersion.findPart(this.template$.value.parts, $event.executionResultPartId);
                if (part) {
                    (part.detail as TemplatePartDetail).templateVersion = plainToClass(TemplateVersion, result.templateVersion);
                }
                this.template$.next(plainToClass(TemplateVersion, Object.assign({}, this.template$.value)));
            } else {
                this.template$.next(
                    plainToClass(TemplateVersion, Object.assign(this.template$.value, { parts: result.templateVersion.parts }))
                );
            }
        }*/
    }
}
