import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActionExecutionLogDto } from '../dto/action-execution-log.dto';

@Injectable({
    providedIn: 'root',
})
export class ActionLogService {
    constructor(private httpClient: HttpClient) {}

    public findActionLogs(
        host: string,
        applicationId: string,
        companyId: string,
        templateId: string,
        actionId: string,
        from: Date,
        to: Date
    ): Observable<ActionExecutionLogDto[]> {
        const params = new HttpParams()
            .set('applicationId', applicationId)
            .set('companyId', companyId)
            .set('from', from.toISOString())
            .set('to', to.toISOString());

        if (!!templateId) {
            params.set('templateId', templateId);
        }
        if (!!actionId) {
            params.set('actionId', actionId);
        }
        return this.httpClient.get<ActionExecutionLogDto[]>(`${host}/v2/action-logs`, { params: params });
    }
}
