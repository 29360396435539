import { createAction as createNgRxAction, props } from '@ngrx/store';
import {
    ActionExecutionCreatedDto,
    ActionExecutionInvocationArgumentDto,
    TemplateVersion,
    TemplateVersionResponse,
} from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../libs/frontoffice/data-access/template/src/lib/models/template-argument.model';
import { FormGroup } from '@angular/forms';

export const requestFetchTemplateById = createNgRxAction(
    '[ACTION] request fetch template by id',
    props<{
        templateId: string | null;
        path: string;
        host;
        templateArguments: TemplateArgument[];
        instanceIdentifier: string;
        languageCode?: string | null;
        webComponent?: boolean;
    }>()
);

export const removeTemplateInstanceFromStore = createNgRxAction(
    '[ACTION] remove template instance from store',
    props<{ instanceIdentifier: string }>()
);
export const requestFetchTemplateByHost = createNgRxAction(
    '[ACTION] request fetch template by host',
    props<{ path: string; host: string; templateArguments: TemplateArgument[]; instanceIdentifier: string }>()
);
export const fetchTemplateByIdSuccess = createNgRxAction(
    '[EFFECT] fetch template by id success',
    props<{ host?: string; templateVersionResponse: TemplateVersionResponse }>()
);

export const fetchBaseTemplateByIdSuccess = createNgRxAction(
    '[EFFECT] fetch base template by id success',
    props<{ host?: string; templateVersionResponse: TemplateVersionResponse; webComponent?: boolean }>()
);

export const fetchTemplateByHostSuccess = createNgRxAction(
    '[EFFECT] fetch template by host success',
    props<{ templateVersionResponse: TemplateVersionResponse }>()
);

export const fetchBaseTemplateByHostSuccess = createNgRxAction(
    '[EFFECT] fetch base template by host success',
    props<{ host: string; templateVersionResponse: TemplateVersionResponse }>()
);

export const fetchTemplateError = createNgRxAction('[ACTION] fetch template error');

export const updateTemplateByPath = createNgRxAction(
    '[ACTION] update template',
    props<{
        templateVersion: TemplateVersion;
    }>()
);

export const noUpdateNeeded = createNgRxAction('[ACTION] no update needed for template');

export const updateTemplateAfterActionSuccess = createNgRxAction('[ACTION] update template after action success');

export const updateTemplateById = createNgRxAction(
    '[ACTION] update template',
    props<{
        templateVersion: TemplateVersion;
    }>()
);

export const updateTemplateSuccess = createNgRxAction(
    '[EFFECT] update template success',
    props<{ templateVersion: TemplateVersionResponse }>()
);

export const requestExecuteOnEnterAction = createNgRxAction(
    '[ACTION] request on enter execute action',
    props<{
        host?: string;
        templateVersionResponse: TemplateVersionResponse;
        webComponent?: boolean;
    }>()
);

export const requestExecuteAction = createNgRxAction(
    '[ACTION] request execute action',
    props<{
        host: string | null;
        trigger: string;
        triggerType: string;
        actionIds: string[];
        templateId: string;
        templateArguments: TemplateArgument[] | ActionExecutionInvocationArgumentDto[];
        executionResultPartId: string;
        byPath: boolean;
        formGroup: FormGroup;
        formTemplateIdentifier: string;
        webComponent: boolean;
        templateVersionResponse?: TemplateVersionResponse;
    }>()
);

export const requestExecuteOnEntryActionsDummy = createNgRxAction(
    '[ACTION] execute on entry action but none to execute',
    props<{ templateVersionResponse: TemplateVersionResponse; byPath: boolean }>()
);

export const requestNoActionsToExecute = createNgRxAction('[ACTION] no actions to execute', props<{ templateVersion: TemplateVersion }>());

export const requestExecuteActionSuccess = createNgRxAction(
    '[ACTION] execute action success',
    props<{
        actionExecutionCreated: ActionExecutionCreatedDto;
        byPath: boolean;
        host: string;
        triggerType?: string;
        executionResultPartId?: string;
        webComponent?: boolean;
    }>()
);

export const requestExecuteActionError = createNgRxAction('[ACTION] execute action success');

export const requestChangeExternalAppTemplate = createNgRxAction(
    '[ACTION] request change external app template',
    props<{
        templateId: string;
        templateArguments: TemplateArgument[];
        instanceIdentifier: string;
        host: string;
        path: string;
    }>()
);
