import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { Router } from '@angular/router';
import { TemplateFacade } from '../../../../../data-access/template/src/lib/facade/template.facade';

declare var resetPassword: any;

@Injectable()
export class ResetPassword implements Method {
    constructor() {}

    execute(executionScope: ExecutionScope): void {
        const email = executionScope.getRequiredVariable('email');
        resetPassword(email);
    }
}
