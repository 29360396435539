import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonPartFrontComponent } from './components/button-part-front/button-part-front.component';
import { getPartDetailComponents } from '../../../part-module';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { A11yModule } from '@angular/cdk/a11y';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonsModule } from '../../../../../../../../../apps/no-code-x-frontoffice/src/app/lib/common/commons.module';

@NgModule({
    declarations: [ButtonPartFrontComponent],
    imports: [
        CommonModule,
        CommonsModule,
        NgxFloatUiModule,
        MatIconModule,
        MatButtonModule,
        MatRippleModule,
        MatTooltipModule,
        A11yModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class ButtonPartModule {
    constructor() {
        getPartDetailComponents().set('button', ButtonPartFrontComponent);
    }
}
