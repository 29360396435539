import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarChartPartModule } from './components/charts/bar-chart-part/bar-chart-part.module';
import { ButtonGroupPartModule } from './components/form/button-group-part/button-group-part.module';
import { ButtonPartModule } from './components/form/button-part/button-part.module';
import { CheckboxFieldPartModule } from './components/form/checkbox-field-part/checkbox-field-part.module';
import { ColorInputFieldPartModule } from './components/form/color-input-field-part/color-input-field-part.module';
import { DateFieldPartModule } from './components/form/date-field-part/date-field-part.module';
import { DropdownFieldPartModule } from './components/form/dropdown-field-part/dropdown-field-part.module';
import { EmailInputFieldPartModule } from './components/form/email-input-field-part/email-input-field-part.module';
import { LineChartPartModule } from './components/charts/line-chart-part/line-chart-part.module';
import { InputFieldPartModule } from './components/form/input-field-part/input-field-part.module';
import { NumberInputFieldPartModule } from './components/form/number-input-field-part/number-input-field-part.module';
import { PasswordInputFieldPartModule } from './components/form/password-input-field-part/password-input-field-part.module';
import { PieChartPartModule } from './components/charts/pie-chart-part/pie-chart-part.module';
import { PlanePartModule } from './components/containers/plane-part/plane-part.module';
import { RadarChartPartModule } from './components/charts/radar-chart-part/radar-chart-part.module';
import { RadioButtonFieldPartModule } from './components/form/radio-button-field-part/radio-button-field-part.module';
import { SliderFieldPartModule } from './components/form/slider-field-part/slider-field-part.module';
import { SlideToggleFieldPartModule } from './components/form/slide-toggle-field-part/slide-toggle-field-part.module';
import { StepperPartModule } from './components/containers/stepper-part/stepper-part.module';
import { TabsPartModule } from './components/containers/tabs-part/tabs-part.module';
import { TextFieldPartModule } from './components/form/text-field-part/text-field-part.module';
import { TreemapPartModule } from './components/charts/treemap-part/treemap-part.module';
import { UrlInputFieldPartModule } from './components/form/url-input-field-part/url-input-field-part.module';
import { TextpartModule } from './components/layout/textpart/textpart.module';
import { IconButtonPartModule } from './components/form/icon-button-part/icon-button-part.module';
import { TitlePartModule } from './components/layout/title-part/title-part.module';
import { HorizontallistPartModule } from './components/containers/horizontallist-part/horizontallist-part.module';
import { VerticallistPartModule } from './components/containers/verticallist-part/verticallist-part.module';
import { IconPartModule } from './components/layout/icon-part/icon-part.module';
import { TemplatePartModule } from './components/layout/template-part/template-part.module';
import { ImagePartModule } from './components/layout/image-part/image-part.module';
import { UploaderPartModule } from './components/form/uploader-part/uploader-part.module';
import { LinkPartModule } from './components/layout/link-part/link-part.module';
import { PagingPartModule } from './components/layout/paging-part/paging-part.module';
import { ChipsInputFieldPartModule } from './components/form/chips-input-field-part/chips-input-field-part.module';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { VerticalDividerModule } from './components/layout/vertical-divider/vertical-divider.module';
import { HorizontalDividerModule } from './components/layout/horizontal-divider/horizontal-divider.module';
import { ChipsPartModule } from './components/layout/chips-part/chips-part.module';
import { DatatablePartModule } from './components/containers/datatable-part/datatable-part.module';
import { OtpQrPartModule } from './components/user/otp-qr-part/otp-qr-part.module';
import { HtmlPartModule } from './components/custom/html-part/html-part.module';
import { AccordionPartModule } from './components/containers/accordion-part/accordion-part.module';
import { TemplateFrontModule } from './components/template-front/template-front.module';

@NgModule({
    imports: [
        CommonModule,
        BarChartPartModule,
        ButtonGroupPartModule,
        ButtonPartModule,
        CheckboxFieldPartModule,
        ColorInputFieldPartModule,
        DateFieldPartModule,
        DropdownFieldPartModule,
        EmailInputFieldPartModule,
        LineChartPartModule,
        InputFieldPartModule,
        NumberInputFieldPartModule,
        PasswordInputFieldPartModule,
        PieChartPartModule,
        PlanePartModule,
        RadarChartPartModule,
        RadioButtonFieldPartModule,
        SliderFieldPartModule,
        SlideToggleFieldPartModule,
        StepperPartModule,
        TabsPartModule,
        TextFieldPartModule,
        TreemapPartModule,
        UrlInputFieldPartModule,
        TextpartModule,
        IconButtonPartModule,
        TitlePartModule,
        HorizontallistPartModule,
        VerticallistPartModule,
        IconPartModule,
        TemplatePartModule,
        ImagePartModule,
        UploaderPartModule,
        LinkPartModule,
        PagingPartModule,
        ChipsInputFieldPartModule,
        MatButtonModule,
        MatListModule,
        VerticalDividerModule,
        HorizontalDividerModule,
        ChipsPartModule,
        DatatablePartModule,
        OtpQrPartModule,
        HtmlPartModule,
        AccordionPartModule,
    ],
    exports: [TemplatePartModule, TemplateFrontModule],
})
export class SharedUiComponentsModule {}
