<img
    *ngIf="partDetail.linkType === 'url'"
    #urlImageContainer
    [id]="'id-' + part.id"
    [matTooltip]="partDetail.processedTitle"
    class="part-content"
    (click)="executeOnClickAction()" />
<img
    *ngIf="partDetail.linkType === 'media-library' && !partDetail.automaticResizing"
    [id]="'id-' + part.id"
    [matTooltip]="partDetail.processedTitle"
    class="part-content"
    [src]="host + '/v2/media/file/' + partDetail.mediaId"
    (click)="executeOnClickAction()" />
<img
    *ngIf="partDetail.linkType === 'media-library' && partDetail.automaticResizing"
    [id]="'id-' + part.id"
    [matTooltip]="partDetail.processedTitle"
    class="part-content"
    (click)="executeOnClickAction()" />
<span
    [id]="'id-' + part.id"
    class="svg-image"
    [matTooltip]="partDetail.processedTitle"
    *ngIf="partDetail.linkType === 'svg'"
    (click)="executeOnClickAction()"
    #svgContainer>
</span>
